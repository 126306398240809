const SET_SELECTED_SELLER = 'SET_SELECTED_SELLER'

/**
 * ACTION CREATORS
 */
export const setSelectedSeller = seller => ({type: SET_SELECTED_SELLER, seller})

/**
 * REDUCER
 */
export default function(state = '', action) {
  switch (action.type) {
    case SET_SELECTED_SELLER:
      return action.seller
    default:
      return state
  }
}
