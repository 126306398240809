import React from 'react'

import './ForgotPasswordModal.css'

/**
 * API Call that triggers sending an email to the user so that
 * they can reset their password
 * @param {Event} ev
 */
async function sendForgotPasswordEmail(ev, email, hooks) {
  ev.preventDefault()

  const {
    changeEmail,
    setMessage,
    setAlertClass,
    forgotPasswordModalToggle
  } = hooks
  let checkForUser
  try {
    checkForUser = await fetch(`/api/account/${email}`, {method: 'GET'})
  } catch (err) {
    // console.log(`/api/account/${email}`, err)
  }
  checkForUser = await checkForUser.json()
  if (!checkForUser.success) {
    changeEmail(null, '')
    setMessage(checkForUser.message)
    if (checkForUser.message === 'Database error. Please try again soon.') {
      setAlertClass(' alert-danger')
    } else {
      setAlertClass(' alert-info')
    }
    return
  }
  try {
    await fetch('/api/account/forgot-password', {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      referrer: 'no-referrer',
      body: JSON.stringify({email})
    })
  } catch (err) {
    // console.log('/api/account/forgot-password', err)
  }
  setMessage('Finished, check your email to reset your password.')
  setAlertClass(' alert-success')
  setTimeout(() => {
    forgotPasswordModalToggle()
  }, 5000)
  changeEmail(null, '')
}

function onClose(hooks) {
  const {
    setMessage,
    setAlertClass,
    changeEmail,
    forgotPasswordModalToggle
  } = hooks
  setMessage('')
  setAlertClass('')
  changeEmail(null, '')
  forgotPasswordModalToggle()
}

/**
 * This creates a modal that pops up when user clicks that they
 * have forgotten their password
 * @param {String} modalShow
 * @param {Function} forgotPasswordModalToggle
 */
export default class forgotPasswordModal extends React.Component {
  state = {
    message: '',
    alertClass: '',
    email: ''
  }

  setMessage = message => this.setState({message})
  setAlertClass = alertClass => this.setState({alertClass})
  changeEmail = (ev, str) => {
    if (ev !== null) this.setState({email: ev.target.value})
    else this.setState({email: str})
  }

  render() {
    const modalShow = this.props.modalShow
    const forgotPasswordModalToggle = this.props.forgotPasswordModalToggle
    const methods = {
      changeEmail: this.changeEmail,
      setMessage: this.setMessage,
      setAlertClass: this.setAlertClass,
      forgotPasswordModalToggle
    }

    return (
      <div
        className="modal forgot-password-modal"
        tabIndex="-1"
        role="dialog"
        data-show={`${modalShow}`}
        data-focus={`${modalShow}`}
        style={{display: modalShow ? 'block' : 'none'}}
      >
        <div className="modal-dialog" role="document">
          <form
            onSubmit={ev =>
              sendForgotPasswordEmail(ev, this.state.email, methods)
            }
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Forgot Password</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => onClose(methods)}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <label htmlFor="forgotPasswordEmail">Email address</label>
                  <input
                    type="email"
                    className="form-control"
                    id="forgotPasswordEmail"
                    aria-describedby="emailHelp"
                    placeholder="Enter your email"
                    value={this.state.email}
                    onChange={this.changeEmail}
                  />
                  <small id="emailHelp" className="form-text text-muted">
                    We'll send you an email to reset it immediately.
                  </small>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  disabled={!this.state.email.length}
                >
                  Submit
                </button>
                <div className={`alert${this.state.alertClass}`}>
                  {this.state.message}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}
