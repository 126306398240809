import React from 'react'
import * as Scroll from 'react-scroll'

import Header from '../Header'
import {
  CDS,
  Broker,
  SectionOne,
  SectionTwo,
  SectionThree,
  SectionFour,
  SectionFive,
  SectionSix,
  SectionSeven
} from './Sections'

const animateScroll = Scroll.animateScroll

class Landing extends React.Component {
  componentDidMount() {
    animateScroll.scrollTo(0, {smooth: true, duration: 500})
  }
  render() {
    return (
      <React.Fragment>
        <Header />
        <SectionOne />
        <SectionTwo />
        <SectionThree />
        <SectionFour />
        <SectionFive />
        <CDS />
        <Broker />
        <SectionSix />
        <SectionSeven />
      </React.Fragment>
    )
  }
}

export default Landing
