import React, { Component } from 'react';
import Header from '../OnboardingHeader';
import Steps from './Steps';

class CreateNewClaim extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      claim: {
        maxClaimAmount: '',
        term: '',
        purchaseRate: '',
        feeRate: '',
        debtor: '',
        buyer: '',
        scp: true
      }
    };
  }

  async componentDidMount() {
    let state = {};
    const location = this.props.location;
    if (location && location.state) {
      state = location.state;
    }
    if (state.claim) {
      let resultOne;
      try {
        resultOne = await fetch('/api/claim/one/' + state.claim, {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json'
          },
          redirect: 'follow',
          referrer: 'no-referrer',
          body: JSON.stringify({ debtor: state.claim })
        });
        resultOne = await resultOne.json();
      } catch (error) {
        console.log(error);
      }
      let resultTwo = { data: [] };
      try {
        resultTwo = await fetch('/api/account', {
          method: 'GET',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json'
          },
          redirect: 'follow',
          referrer: 'no-referrer'
        });
        resultTwo.data = await resultTwo.json();
      } catch (error) {
        console.log(error);
      }
      this.setState({ claim: resultOne.claim, users: resultTwo.data });
    }
  }

  render() {
    return (
      <React.Fragment>
        <Header />
        <Steps
          claim={this.state.claim}
          users={this.state.users}
          account={this.props.account}
        />
      </React.Fragment>
    );
  }
}

export default CreateNewClaim;
