import React from 'react';
import { connect } from 'react-redux';
import './AdminPanel.css';

import Header from '../OnboardingHeader';
import ClaimPanelContent from '../ClaimPanelContent';
import UserPanelContent from '../UserPanelContent';
import AgreementPanelContent from '../AgreementPanelContent';
import TransactionsPanelContent from '../TransactionsPanelContent/TransactionsPanelContent';

import { fetchAllUsers } from '../../store';

class AdminPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 'claims'
    };
    this.handleTabChange = this.handleTabChange.bind(this);
  }

  handleTabChange(event) {
    this.setState({
      tab: event.target.dataset.name
    });
  }

  componentDidMount() {
    this.props.initAllUsers();
  }

  render() {
    return (
      <React.Fragment>
        <Header />
        <div id="admin-panel">
          <div className="col panel-container">
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <a
                  data-name="claims"
                  className={`nav-link ${
                    this.state.tab === 'claims' ? 'active' : ''
                  }`}
                  onClick={this.handleTabChange}
                >
                  CLAIMS
                </a>
              </li>
              <li className="nav-item">
                <a
                  data-name="users"
                  className={`nav-link ${
                    this.state.tab === 'users' ? 'active' : ''
                  }`}
                  onClick={this.handleTabChange}
                >
                  USERS
                </a>
              </li>
              <li className="nav-item">
                <a
                  data-name="agreements"
                  className={`nav-link ${
                    this.state.tab === 'agreements' ? 'active' : ''
                  }`}
                  onClick={this.handleTabChange}
                >
                  AGREEMENTS
                </a>
              </li>
              <li className="nav-item">
                <a
                  data-name="transactions"
                  className={`nav-link ${
                    this.state.tab === 'transactions' ? 'active' : ''
                  }`}
                  onClick={this.handleTabChange}
                >
                  TRANSACTIONS
                </a>
              </li>
            </ul>
            {this.getContent()}
          </div>
        </div>
      </React.Fragment>
    );
  }

  getContent() {
    switch (this.state.tab) {
      case 'claims':
        return <ClaimPanelContent />;
      case 'users':
        return <UserPanelContent />;
      case 'agreements':
        return <AgreementPanelContent />;
      case 'transactions':
        return <TransactionsPanelContent />;
      default:
        return '';
    }
  }
}

const mapState = state => {
  return {
    users: state.user
  };
};

const mapDispatch = dispatch => {
  return {
    initAllUsers() {
      dispatch(fetchAllUsers());
    }
  };
};

export default connect(mapState, mapDispatch)(AdminPanel);
