import React from 'react'
import {Button, Row, Col} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import './styles/CDS.css'

const CDS = () => {
  return (
    <div id="cds-background">
      <div id="cds" className="section">
        <Row className="text-section">
          <Row>
            <Col md={12}>
              <h1>PutQuote Pricing</h1>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <p>The PutQuote prices listed on the charts below are our estimates of actual vendor 
                put option prices that would be agreed to between a Vendor and an 
                Investor for a 6 month term. PutQuote prices are calculated using a proprietary 
                formula driven by Credit Default Swap prices, bond prices, loan prices and 
                market conditions. PutQuote prices are not official quotes, but can 
                be used as a starting point to determine how best to manage the credit 
                risk of your Customer. Feel free to contact us at <a href="mailto:all@cherokeeacq.com">all@cherokeeacq.com</a> for more information.</p>
            </Col>
          </Row>
        </Row>
        <Row className="chart-link">
          <Link to="/putquote">
            <Button className="btn-default">View PutQuote Pricing</Button>
          </Link>
        </Row>
      </div>
    </div>
  )
}

export default CDS
