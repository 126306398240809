import React from 'react';
import { Image, Button } from 'react-bootstrap';
import axios from 'axios';
import history from '../../history';
import Header from '../OnboardingHeader';
import './SignAgreement.css';

export default class StepTwo extends React.Component {
  constructor(props) {
    super(props);
    this.onButtonClick = this.onButtonClick.bind(this);
  }

  state = {
    disabled: false,
    emailSent: false
  };

  onButtonClick() {
    this.setState({ disabled: true });
    console.log('wtf');
    axios
      .get('/api/docusign')
      .then(() => {
        this.setState({ disabled: true, emailSent: true });
        setTimeout(() => history.push('/profile'), 8000);
      })
      .catch(function(error) {});
  }

  render() {
    return (
      <React.Fragment>
        <Header />
        <div id="step-container">
          <div id="sign-agreement">
            <div className="container">
              <div className="row">
                <div className="col top-col">
                  <p className="title text-center">
                    Please follow the instructions provided by DocuSign to
                    accept the use of Claims Put Market.
                  </p>
                  <Image
                    className="image-section mx-auto"
                    src="/assets/icons/docSign-logo.png"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="form-section card">
                    <div className="card-header text-center">
                      STEP TWO: AGREEMENT
                    </div>
                    <div className="card-body">
                      <p>
                        When you click the 'SIGN AGREEMENT' button, you will be
                        sent a DocuSign email containing the agreement for you
                        to sign. Once you have signed the Agreement, you will be
                        able to buy or sell a vendor put option on the website.
                      </p>
                    </div>
                    <div className="card-footer text-center">
                      {/*<p className="text-left">
                        By clicking the ‘SIGN AGREEMENT’ button below, you
                        accept the Terms & Conditions and
                        acknowledge that your data will be used as described in
                        the DocuSign Privacy Policy.
                      </p>*/}
                      {this.state.emailSent ? (
                        <div className="alert alert-info" role="alert">
                          Thank you! You will receive the agreement by email
                          shortly. Please sign the agreement before further
                          actions!
                        </div>
                      ) : (
                        <Button
                          type="submit"
                          className="btn-default"
                          onClick={this.onButtonClick}
                          disabled={this.state.disabled}
                        >
                          Sign Agreement
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
