import {createStore, combineReducers, applyMiddleware} from 'redux'
import thunkMiddleware from 'redux-thunk'
import {composeWithDevTools} from 'redux-devtools-extension'
import account from './account'
import claims from './claims'
import users from './users'
import selectedAccountDebtor from './selectedAccountDebtor'
import selectedSeller from './selectedSeller'

const reducer = combineReducers({
  account,
  claims,
  users,
  selectedAccountDebtor,
  selectedSeller
})
const middleware = composeWithDevTools(
  // applyMiddleware(thunkMiddleware, createLogger({collapsed: true}))
  applyMiddleware(thunkMiddleware)
)
const store = createStore(reducer, middleware)

export default store

export * from './claims'
export * from './account'
export * from './users'
export * from './selectedAccountDebtor'
export * from './selectedSeller'
