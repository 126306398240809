import React from 'react';
import { isEqual } from 'lodash';
import ConfirmClaim from './ConfirmClaim.js';
import TradeConfirm from './TradeConfirm.js';
import './index.css';

export default class Steps extends React.Component {
  constructor() {
    super();
    this.state = {
      createClaimStep: 2
    };
    this.setCreateClaimStep = this.setCreateClaimStep.bind(this);
    this.getStepComponent = this.getStepComponent.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      !isEqual(nextProps.claim, this.props.claim) ||
      !isEqual(nextState.createClaimStep, this.state.createClaimStep)
    ) {
      return true;
    } else {
      return false;
    }
  }

  setCreateClaimStep(createClaimStep) {
    this.setState({ createClaimStep });
  }

  render() {
    return (
      <div id="createClaimSteps" className="section-signup">
        {this.getStepComponent(this.state.createClaimStep)}
      </div>
    );
  }

  getStepComponent(step) {
    switch (step) {
      case 2:
        return (
          <ConfirmClaim
            setCreateClaimStep={this.setCreateClaimStep}
            setSCP={this.setSCP}
            claim={this.props.claim}
            account={this.props.account}
            users={this.props.users}
          />
        );
      case 3:
        return (
          <TradeConfirm
            claim={this.props.claim}
            account={this.props.account}
            users={this.props.users}
          />
        );
      default:
        return <React.Fragment />;
    }
  }
}
