import React, { Component } from 'react';
import Header from '../Header';
import * as Scroll from 'react-scroll';

import './FAQ.css';

const animateScroll = Scroll.animateScroll;

export default class FAQ extends Component {
  componentDidMount() {
    animateScroll.scrollTo(0, { smooth: true, duration: 500 });
  }
  render() {
    return (
      <div id="faq">
        <Header />
        <div className="faq-header">
          <div className="shade">
            <h1>FAQ</h1>
          </div>
        </div>
        <div className="faq-page container">
          <div className="row">
            <div className="col-12 col-sm-10 mx-auto">
              <h5>
                What are the advantages to listing my requested put option on
                Claims Put Market, as opposed to buying from an Investor of put
                options who has contacted me?
              </h5>
            </div>
          </div>
          <div className="row list-section">
            <div className="col-12 col-sm-10 mx-auto">
              <div>
                <div>
                  <div className="number-list">
                    <p clessName="list-number">1. </p>
                    <p>
                      Better pricing. By listing your requested put option on
                      Claims Put Market, you are more likely to buy a put option
                      at the best price.
                    </p>
                  </div>
                  <ol type="a">
                    <li>
                      More Investors see your requested put option. Investors
                      who could not find you before, can now see your requested
                      put option. Claims Put Market brings out more Investors
                      and tips the supply/demand balance in your favor.
                    </li>
                    <li>
                      More competitive markets. The transparency of Claims Put
                      Market will encourage competition between Investors and
                      push prices lower.
                    </li>
                  </ol>
                </div>
                <div>
                  <div className="number-list">
                    <p clessName="list-number">2. </p>
                    <p>
                      Simple documents. You see everything upfront that you need
                      to close.
                    </p>
                  </div>
                  <ol type="a">
                    <li>
                      Our Simple Claims Put (“SCP”) and Simple Assignment of
                      Claim (“SAC”), are displayed on our website.
                    </li>
                    <li>
                      If you choose to make specific changes to the SCP or SAC,
                      negotiating time and money is minimized since an Investor
                      only needs to focus on a Vendor’s specific red-lined
                      changes to the SCP or SAC.
                    </li>
                  </ol>
                </div>
                <div>
                  <div className="number-list">
                    <p clessName="list-number">3. </p>
                    <p>Financially strong put option Investors.</p>
                  </div>
                  <ol type="a">
                    <li>
                      Claims Put Market qualifies all Investors as a part of our
                      on-boarding process by obtaining and reviewing each
                      Investor’s financial statements.
                    </li>
                    <li>
                      We will only match a Vendor with an Investor if the
                      Investor’s financial statements have equity of at least
                      ten times (10x) the Maximum Claim Amount referenced in the
                      SCP.
                    </li>
                    <li>
                      After Claims Put Market has matched the key terms of your
                      requested put option with an Investor, and after you have
                      signed a Confidential Information Agreement with an
                      Investor, Claims Put Market will provide you with the
                      Investor’s financial statements.
                    </li>
                  </ol>
                </div>
                <div>
                  <div className="number-list">
                    <p clessName="list-number">4. </p>
                    <p>
                      Fast closing. The closing process is fast and transparent.
                    </p>
                  </div>
                  <ol type="a">
                    <li>
                      The fast closing process has been agreed to by Vendors and
                      Investors in advance when they each sign a Vendor’s
                      Agreement and Investor’s Agreement.
                    </li>
                    <li>
                      After price and terms are matched, a Vendor is provided
                      with the matched Investor’s financial statements. The
                      Vendor is required to complete its review of the
                      Investor’s financial statements by close of the next
                      business day. If Vendor:
                      <ol type="i">
                        <li>
                          approves Investor --> transaction confirmed. Investor
                          signs the SCP and Vendor wire transfers the Put Option
                          Fee to Investor.
                        </li>
                        <li>
                          rejects Investor --> no deal. However, Vendor will not
                          see any other offer from Investor for 6 months.
                        </li>
                        <li>
                          does not respond --> no deal. However, Vendor will be
                          excluded from Claims Put Market for 6 months.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-10 mx-auto content-section">
            <h5>What is a Simple Claims Put ("SCP")?</h5>
            <p>
              If you are a Vendor who is concerned that your customer
              (“Customer”) may file bankruptcy and you want to protect the value
              of your valid receivables, a SCP may offer the loss protection you
              need.
            </p>
            <p>
              A Vendor buys a SCP from an Investor. In exchange for an upfront
              fee, the Investor makes an irrevocable commitment to purchase your
              valid bankruptcy claim (if a claim is created as a result of your
              Customer’s bankruptcy filing) at a pre-determined price (“Purchase
              Rate”). If your Customer does not file for bankruptcy protection
              during the term of the SCP (“Term”), the SCP expires and the
              Investor keeps the fee. If the Customer does file for bankruptcy
              protection during the Term, and the Vendor properly exercises the
              SCP, the Investor is obligated to purchase the Vendor’s valid
              bankruptcy Claim at the Purchase Rate, subject to the terms of the
              SCP and the Simple Assignment of Claim. If you ship to a risky
              Customer, create a receivable and your Customer files for
              bankruptcy, your potential loss is not known. With the SCP, you
              buy the option to sell your valid bankruptcy Claim to an Investor
              at the Purchase Rate.
            </p>
          </div>
          <div className="col-12 col-sm-10 mx-auto content-section">
            <h5>Key Concept</h5>
            <p>
              A SCP is <strong className="underlined-text">not</strong> linked
              to specific invoices. This allows a Vendor to protect multiple
              cycles of invoices, depending on the Term of the SCP and the
              payment speed of the Customer.
            </p>
          </div>
          <div className="col-12 col-sm-10 mx-auto">
            <h5>Example</h5>
            <table className="table">
              <tr>
                <th>SCP terms</th>
              </tr>
              <tr>
                <td>Max. Claim Amount:</td>
                <td>$1,000,000</td>
              </tr>
              <tr>
                <td>Term:</td>
                <td>6 months</td>
              </tr>
              <tr>
                <td>Purchase Rate:</td>
                <td>90%</td>
              </tr>
              <tr>
                <td>Fee Rate</td>
                <td>2% monthly ($120,000 total)</td>
              </tr>
            </table>
            <table className="table">
              <tr>
                <th>Assumptions</th>
              </tr>
              <tr>
                <td>Gross Margin:</td>
                <td>25% (per invoice)</td>
              </tr>
              <tr>
                <td>Invoice cycles:</td>
                <td>2 cycles (before Customer's bankruptcy)</td>
              </tr>
              <tr>
                <td>Customer:</td>
                <td>Files bankruptcy in 4 months</td>
              </tr>
              <tr>
                <td>Unsecured Claims:</td>
                <td>Are worth 10%</td>
              </tr>
              <tr>
                <td>Vendor:</td>
                <td>
                  Has a valid bankruptcy Claim in the amount of $1,000,000
                </td>
              </tr>
            </table>
            <table className="table">
              <tr>
                <th>Outcome</th>
              </tr>
              <tr>
                <th />
                <th>With put option</th>
                <th>Without put option</th>
              </tr>
              <tr>
                <td>Gross Margin:</td>
                <td>$500,000 ($1 million x 25% x 2 cycles)</td>
                <td>$500,000 ($1 million x 25% x 2 cycles)</td>
              </tr>
              <tr>
                <td>Put Option Fee:</td>
                <td>- $120,000</td>
                <td>- $0</td>
              </tr>
              <tr>
                <td>Loss on Claim:</td>
                <td>- $100,000 ($1 million x [90% rate - 100%])</td>
                <td>- $900,000 ($1 million x [10% value - 100%])</td>
              </tr>
              <tr>
                <td>Profit/Loss:</td>
                <td>$280,000</td>
                <td>- $400,000</td>
              </tr>
            </table>
            <h4>$680,000 benefit</h4>
          </div>
        </div>
      </div>
    );
  }
}
