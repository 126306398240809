import React from 'react';

import './TradeConfirmModal.css';

export default function TradeConfirmModal(changeConfirmed, modalShow) {
  return (
    <div
      className="modal confirm-modal"
      tabIndex="-1"
      role="dialog"
      data-show={`${modalShow}`}
      data-focus={`${modalShow}`}
      style={{ display: modalShow ? 'block' : 'none' }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Trade Confirmation</h5>
          </div>
          <div className="modal-body">
            <p>
              Are you sure? You are about to make a binding commitment to
              sell a put option.
            </p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={() => changeConfirmed(false)}
            >
              No
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => changeConfirmed(true)}
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
