import React from 'react'
import Header from '../OnboardingHeader'
import Steps from './Steps'

const SignUp = () => {
  return (
    <React.Fragment>
      <Header />
      <Steps />
    </React.Fragment>
  )
}

export default SignUp
