import React from 'react';
import { connect } from 'react-redux';
import './SignUp.css';
import EditProfileForm from '../../EditProfileForm';
import { signUp } from '../../../store';

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      company: '',
      phone: '',
      email: '',
      password: '',
      accountType: '',
      error: ''
    };
    this.onFormOptionChange = this.onFormOptionChange.bind(this);
    this.onFormValueChange = this.onFormValueChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  onFormOptionChange(event) {
    this.setState({
      accountType: event.target.dataset.name
    });
  }

  onFormSubmit(event) {
    event.preventDefault();
    const form = {
      name: this.state.name,
      company: this.state.company,
      phone: this.state.phone,
      email: this.state.email,
      password: this.state.password,
      accountType: this.state.accountType,
      agreementSigned: true
    };
    if (isValid(form.email)) {
      this.props.submitSignUpForm(form);
    } else {
      this.setState({
        error: 'Please enter a valid email address.'
      });
    }
  }

  onFormValueChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  render() {
    const form = {
      name: this.state.name,
      company: this.state.company,
      phone: this.state.phone,
      email: this.state.email,
      password: this.state.password,
      accountType: this.state.accountType
    };
    return (
      <div id="steps" className="section-signup">
        <div id="step-one">
          <div className="container">
            <div className="row">
              <div className="col">
                <p className="title text-center">
                  Please proceed with our two-step onboarding to begin
                  <br />
                  buying or listing claims.
                </p>
              </div>
            </div>
            <EditProfileForm
              title="STEP ONE: SIGN UP"
              error={this.state.error}
              value={form}
              onFormOptionChange={this.onFormOptionChange}
              onFormValueChange={this.onFormValueChange}
              onFormSubmit={this.onFormSubmit}
              buttonTitle="CONTINUE TO AGREEMENT"
            />
          </div>
        </div>
      </div>
    );
  }
}

function isValid(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const mapState = state => {
  return {
    user: state.user
  };
};

const mapDispatch = dispatch => {
  return {
    submitSignUpForm(userInfo) {
      dispatch(signUp(userInfo));
    }
  };
};

export default connect(mapState, mapDispatch)(SignUp);
