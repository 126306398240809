import React from 'react'
import {Image, Button, Row, Col} from 'react-bootstrap'
import './styles/SectionFour.css'

const SectionFour = () => {
  return (
    <div id="section-four-background">
      <div id="section-four" className="section">
        <Row>
          <Col md={7} className="text-section">
            <Row>
              <Col md={12}>
                <h1>
                  Investor's
                  <br />
                  Agreement
                </h1>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <h5>TRANSACTION PROCESS</h5>
                <p>
                  When Vendor and Investor terms are matched, Vendor and Investor sign 
                  a confidentiality agreement.  We then provide the Vendor with a 
                  copy of the Investor’s financial statements.  If Vendor approves 
                  of the Investor, a transaction is confirmed. Once a transaction 
                  is confirmed, a fully executed SCP with all exhibits will be 
                  sent to both parties with the winning Investor identified as “Investor” 
                  in the SCP.  The Vendor will pay the Put Option Fee to Investor within 
                  two business days of the full execution of the SCP.
                </p>
              </Col>
              <Col md={12}>
                <h5>ADMINISTRATIVE FEE</h5>
                <p>
                  Investors agree to pay Claims Put Market an Administrative Fee 
                  stated on the Trade Summary page of our site with respect to 
                  each put option they sell to a Vendor.  Affiliates of Claims 
                  Put Market may offer to sell put options (after five business 
                  days of a Vendor’s listing) or request to buy put options on the site.
                </p>
              </Col>
            </Row>
          </Col>
          <Col md={5} className="image-section">
            <Image src="/assets/icons/sac-doc.svg" />
            <a>
              <Button
                className="btn-default"
                onClick={() =>
                  window.open(
                    `https://s3.us-east-2.amazonaws.com/claims-put-market-assets/investorsAgreement.pdf`,
                    '_blank'
                  )
                }
              >
                VIEW INVESTOR'S AGREEMENT
              </Button>
            </a>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default SectionFour
