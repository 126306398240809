import React from 'react';
import { Button, Image } from 'react-bootstrap';
import * as Scroll from 'react-scroll';

import TradeConfirmModal from './TradeConfirmModal';
import moment from 'moment'

import './ConfirmClaim.css';
import {
  numberWithCommas,
  convertDecimal,
  createListGroupItem,
  calculateFee
} from './utils';

const animateScroll = Scroll.animateScroll;

class ConfirmClaim extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
      heightOfTopCards: null,
      widthOfTopCards: null
    };
    this.onConfirmClick = this.onConfirmClick.bind(this);
    this.changeConfirmed = this.changeConfirmed.bind(this);
  }

  componentDidMount() {
    animateScroll.scrollTo(0, { smooth: true, duration: 500 });
  }

  onConfirmClick() {
    this.setState({ modalShow: true });
  }

  async changeConfirmed(confirmed) {
    this.setState({ modalShow: false });
    if (confirmed === false) {
      return;
    }
    let result;
    try {
      result = await fetch('/api/claim/confirm', {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrer: 'no-referrer',
        body: JSON.stringify({
          account: this.props.account,
          claim: this.props.claim
        })
      });
      result = await result.json();
    } catch (err) {
      result = { success: false };
    }
    if (confirmed) {
      this.props.setCreateClaimStep(3);
    }
  }

  handleCheck = ev => {
    this.setState({ selected: ev.target.name });
  };

  render() {
    const {
      claim: {
        maxClaimAmount,
        term,
        purchaseRate,
        expirationDate,
        effectiveDate,
        listingExpiration,
        feeRate,
        adminFee,
        debtor,
        buyer
      },
      users
    } = this.props;

    const seller = users.find(user => user.company === buyer) || {};

    return (
      <div id="confirm-claim">
        {TradeConfirmModal(this.changeConfirmed, this.state.modalShow)}
        <div className="row">
          <div className="col">
            <div className="image-section mx-auto">
              <p className="text-uppercase">{debtor}</p>
              <Image src="/assets/icons/arrow-right.svg" />
              <p className="text-uppercase">{buyer}</p>
            </div>
          </div>
        </div>
        <div className="row top-row">
          <div className="col col-md-8 col-lg-6 col-xl-5 col-table">
            <div
              className="card"
              style={{
                height: this.state.heightOfTopCards,
                width: this.state.widthOfTopCards
              }}
            >
              <div className="card-header">
                <strong>PUT OPTION SUMMARY</strong>
              </div>
              <div className="card-body">
                <ul className="list-group list-group-flush">
                  {createListGroupItem(
                    'Customer',
                    `${debtor}`
                  )}
                  {createListGroupItem(
                    'Vendor',
                    `${buyer}`
                  )}
                  {createListGroupItem(
                    'Changes to Simple Claims Put',
                    <div className="form-check-container">
                          <div className="form-check radio">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="withoutRedline"
                              defaultChecked={!seller.hasRedlineSac}
                              disabled={seller.hasRedlineSac}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="not-scp"
                            >
                              NO
                            </label>
                          </div>
                          <div className="form-check radio">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="hasRedline"
                              defaultChecked={seller.hasRedlineSac}
                              disabled={!seller.hasRedlineSac}
                            />
                            <label className="form-check-label" htmlFor="scp">
                              YES
                            </label>
                          </div>
                        </div>
                  )}
                  {!seller.hasRedlineSac && (createListGroupItem('View SCP',
                            <a
                              onClick={() =>
                                window.open(
                                  `https://s3.us-east-2.amazonaws.com/claims-put-market-assets/scpAgreement.pdf`,
                                  '_blank'
                                )
                              }
                            >
                              <img src="/assets/icons/doc.svg" alt="doc" />
                            </a>)
                  )}
                   {seller.hasRedlineSac && (createListGroupItem('View Red-line Changes to SCP',
                            <a
                              onClick={() =>
                                window.open(
                                  `https://s3.us-east-2.amazonaws.com/claims-put-market-assets/redlineSac-${
                                    seller._id
                                  }.${
                                    seller.redlineSacExt
                                      ? seller.redlineSacExt
                                      : 'pdf'
                                  }`,
                                  '_blank'
                                )
                              }
                            >
                              <img src="/assets/icons/doc.svg" alt="doc" />
                            </a>)
                  )}
                  {createListGroupItem(
                    'Max. Claim Amount:',
                    `$${numberWithCommas(maxClaimAmount)}`
                  )}
                  {createListGroupItem(
                    'Fee Rate:',
                    `${convertDecimal(feeRate)}% per month`
                  )}
                   {createListGroupItem(
                    'Purchase Rate:',
                    `${convertDecimal(purchaseRate)}%`
                  )}
                  {createListGroupItem(
                    'Effective Date:', 
                    `${moment(effectiveDate).format('LL')}`
                  )}
                  {createListGroupItem(
                    'Expiration Date:', 
                    `${moment(expirationDate).format('LL')}`
                  )}
                  {createListGroupItem(
                    'Put Option Fee:', calculateFee(maxClaimAmount, term, feeRate) + '.00'
                  )}
                  {createListGroupItem(
                    'Admin Fee:',
                    `$${numberWithCommas(adminFee || 0)}.00`
                  )}
                  {createListGroupItem(
                    'Listing Expires:',
                    `${moment(listingExpiration).format('LL')}`
                  )}
                </ul>
              </div>
              <div className="card-footer">
                <Button
                  type="submit"
                  className="btn btn-default"
                  onClick={() => this.onConfirmClick()}
                >
                  TRADE CONFIRMATION
                </Button>
              </div>
            </div>
          </div>
          {/* <div className="col col-md-8 col-lg-6 col-xl-4 col-table col-second">
            <div
              className="card"
              style={{
                height: this.state.heightOfTopCards,
                width: this.state.widthOfTopCards
              }}
            >
              <div className="card-header">
                <strong>Gross Margin:</strong>
              </div>
              <div className="card-body">
                <ul className="list-group list-group-flush">
                  {createListGroupItem('Debtor (customer):', debtor)}
                  {createListGroupItem('Buyer (vendor):', buyer)}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-12 col-third">
            <div className="card">
              <div className="card-header">
                <strong>Outcome:</strong>
              </div>
              <div className="card-body">
                <ul className="list-group list-group-flush scp-data">
                  <h6>
                    <i className="red">With SCP</i>
                  </h6>
                  {createListGroupItem(
                    'Fee:',
                    calculateFee(maxClaimAmount, term, feeRate, false)
                  )}
                  {createListGroupItem(
                    'Loss on claim:',
                    calculateLoss(
                      maxClaimAmount,
                      purchaseRate,
                      false,
                      true,
                      true
                    )
                  )}
                </ul>
                <ul className="list-group list-group-flush without-scp-data">
                  <h6>
                    <i className="red">Without SCP</i>
                  </h6>
                  {createListGroupItem('Fee:', calculateFee(0, 0, 0, false))}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-12 col-fourth">
            <div className="alert alert-info" role="alert">
              <h5>
                <strong>
                </strong>
              </h5>
            </div>
          </div>
          <div className="col-12 col-fifth">
            <form>
              <div className="card">
                <div className="card-header">
                  <strong>
                    Please choose which option you would like to proceed with:
                  </strong>
                </div>
                <div className="card-body">
                  <div className="form-check scp">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="scp"
                      value="scp"
                      checked={this.state.selected === 'scp'}
                      onChange={this.handleCheck}
                    />
                    <label className="form-check-label" htmlFor="scp">
                      SCP
                    </label>
                  </div>
                  <div className="form-check without-scp">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="without-scp"
                      name="without-scp"
                      checked={this.state.selected === 'without-scp'}
                      onChange={this.handleCheck}
                    />
                    <label className="form-check-label" htmlFor="without-scp">
                      Without SCP
                    </label>
                  </div>
                </div>
              </div>
            </form>
          </div> */}
        </div>
      </div>
    );
  }
}

export default ConfirmClaim;
