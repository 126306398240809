import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as Scroll from 'react-scroll';
import moment from 'moment'

import './TradeConfirm.css';
import {
  createDataListItem,
  numberWithCommas,
  convertDecimal,
  calculateFee
} from './utils';

const animateScroll = Scroll.animateScroll;

class TradeConfirm extends Component {
  componentDidMount() {
    animateScroll.scrollTo(0, { smooth: true, duration: 500 });
  }

  render() {
    const {
      claim: {
        maxClaimAmount,
        feeRate,
        purchaseRate,
        buyer,
        expirationDate,
        adminFee,
        debtor,
        term
      },
      users
    } = this.props;

    const seller = users.find(user => user.company === buyer) || {};

    const hasRedlineSac = seller.hasRedlineSac;

    const putOptionFee = calculateFee(maxClaimAmount, term, feeRate);

    return (
      <div id="trade-confirm">
        <div className="container">
          <div className="row">
            <div className="col col-12">
              <p className="title text-center">
                Thanks for the trade! You will also receive the Trade Summary by
                email.
              </p>
            </div>
          </div>
          <div className="form-section card mx-auto">
            <div className="card-header text-center">
              <h4>Trade Confirmation</h4>
            </div>
            <div className="card-body mx-auto">
              <p>You have agreed to sell the following put option:</p>
              <div className="col-12 col-third">
                <div className="card">
                  <div className="card-body">
                    <dl>
                      <dt>CUSTOMER</dt>
                      <dd>{debtor}</dd>
                      <dt>VENDOR</dt>
                      <dd>{buyer}</dd>
                      <dt>CHANGES TO SIMPLE CLAIMS PUT:</dt>
                      <dd>
                        <div className="form-check-container">
                        
                        <div className="form-check radio">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="withoutRedline"
                              defaultChecked={!hasRedlineSac}
                              disabled={hasRedlineSac}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="not-scp"
                            >
                              NO
                            </label>
                          </div>

                          <div className="form-check radio">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="hasRedline"
                              defaultChecked={hasRedlineSac}
                              disabled={!hasRedlineSac}
                            />
                            <label className="form-check-label" htmlFor="scp">
                              YES
                            </label>
                          </div>

                        </div>
                      </dd>
                      {!hasRedlineSac && (
                        <React.Fragment>
                          <dt>VIEW SCP</dt>
                          <dd>
                            <a
                              onClick={() =>
                                window.open(
                                  `https://s3.us-east-2.amazonaws.com/claims-put-market-assets/scpAgreementFVie.pdf`,
                                  '_blank'
                                )
                              }
                            >
                              <img src="/assets/icons/doc.svg" alt="doc" />
                            </a>
                          </dd>
                        </React.Fragment>
                      )}
                      {hasRedlineSac && (
                        <React.Fragment>
                          <dt>VIEW RED-LINE CHANGES TO SCP</dt>
                          <dd>
                            <a
                              onClick={() =>
                                window.open(
                                  `https://s3.us-east-2.amazonaws.com/claims-put-market-assets/redlineSac-${
                                    seller._id
                                  }.${
                                    seller.redlineSacExt
                                      ? seller.redlineSacExt
                                      : 'pdf'
                                  }`,
                                  '_blank'
                                )
                              }
                            >
                              <img src="/assets/icons/doc.svg" alt="doc" />
                            </a>
                          </dd>
                        </React.Fragment>
                      )}
                    </dl>
                    <dl className="row bottom-list border">
                      <dt className="col-md-6">Max. Claim Amount:</dt>
                      <dd className="col-md-6">
                        ${numberWithCommas(maxClaimAmount || 0)}
                      </dd>
                      <dt className="col-md-6">Fee Rate:</dt>
                      <dd className="col-md-6">
                        {convertDecimal(feeRate)}% per month
                      </dd>
                      <dt className="col-md-6">Purchase Rate:</dt>
                      <dd className="col-md-6">
                        {convertDecimal(purchaseRate)}%
                      </dd>
                      <dt className="col-md-6">Expiration Date:</dt>
                      <dd className="col-md-6">{moment(expirationDate).format('LL')}</dd>
                      <dt className="col-md-6">Put Option Fee:</dt>
                      <dd className="col-md-6">{putOptionFee}</dd>
                      <dt className="col-md-6">Admin. Fee:</dt>
                      <dd className="col-md-6">
                        ${numberWithCommas(adminFee || 0)}
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
              <p>
                Please note that within two (2) business days of receiving the
                Put Option Fee, you are required to pay the Admin. Fee to us.
              </p>
            </div>
            <div className="card-footer text-center">
              <Link to="/">
                <Button type="submit" className="btn-default">
                  HOME
                </Button>
              </Link>
              <Link to="/profile">
                <Button type="submit" className="btn-default">
                  PROFILE
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TradeConfirm;
