import React from 'react'
import {Image} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import './Header.css'

const Header = () => {
  return (
    <div id="onboarding-header">
      <Link to="/">
        <Image src="/assets/images/logo-one-all-orange.png" />
      </Link>
    </div>
  )
}

export default Header
