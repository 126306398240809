import axios from 'axios';

/**
 * ACTION TYPES
 */
const GET_ALL_CLAIMS = 'GET_ALL_CLAIMS';

/**
 * INITIAL STATE
 */
const defaultClaims = [];

/**
 * ACTION CREATORS
 */
const getAllClaims = claims => ({ type: GET_ALL_CLAIMS, claims });

/**
 * THUNK CREATORS
 */
export const fetchAllClaims = () => async dispatch => {
  try {
    const res = await axios.get('/api/claim');
    dispatch(getAllClaims(res.data || defaultClaims));
  } catch (err) {
    // do something
  }
};

export const putClaimInfo = (id, claim) => async dispatch => {
  let res;
  try {
    res = await axios.put(`/api/claim/${id}`, claim);
  } catch (err) {
    // do something
  }

  try {
    res = await axios.get('/api/claim');
    dispatch(getAllClaims(res.data || defaultClaims));
  } catch (dispatchOrHistoryErr) {
    // do something
  }
};

/**
 * Posts a new claim - occurs from the admin panel
 * 1. Posts claim
 * 2. Stores document in a separate API call
 * 3. Gets all claims
 * 4. Sends email to all users about the new claim
 * 5. Stores claim and claims in redux store
 */
export const postClaim = claim => async dispatch => {
  let res;
  try {
    res = await axios.post(`/api/claim`, claim);
  } catch (err) {
    /* do something */
  }
  let id = res.data._id;
  try {
    res = await axios.get('/api/claim');
  } catch (err) {
    /* do something */
  }
  const account = res.data.account;

  let file;
  if (claim.file && claim.file[0]) {
    const formData = new FormData();
    formData.append('file', claim.file[0]);
    try {
      file = await axios.post(`/api/claim/${id}/fileupdate`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    } catch (err) {
      // do something
      // console.log(err)
    }
  }

  if (claim.sendEmail == true) {
    try {
      await axios.post('/api/claim/send-email-to-buyers', {
        claim,
        account,
        file
      });
    } catch (err) {
      console.log('error sending email', err);
    }
  }

  dispatch(getAllClaims(res.data || defaultClaims));
};

export const deleteClaim = claimId => async dispatch => {
  try {
    await axios.delete(`/api/claim/${claimId}`);
  } catch (err) {
    // do something
  }

  try {
    const res = await axios.get('/api/claim');
    dispatch(getAllClaims(res.data || defaultClaims));
  } catch (dispatchOrHistoryErr) {
    // do something
  }
};

export const confirmClaim = data => async dispatch => {
  let result;
  try {
    // TODO: if this fails, it is not handled
    result = await axios.post(`/api/claim/confirm`, data);
  } catch (err) {
    // do something
  }

  try {
    const res = await axios.get('/api/claim');
    dispatch(getAllClaims(res.data || defaultClaims));
  } catch (dispatchOrHistoryErr) {
    // do something
  }
};

export const listNewClaim = data => async () => {
  try {
    await axios.post(`/api/claim/listnewclaim`, data);
  } catch (err) {
    // do something
  }
};

/**
 * REDUCER
 */
export default function(state = defaultClaims, action) {
  switch (action.type) {
    case GET_ALL_CLAIMS:
      return action.claims;
    default:
      return state;
  }
}
