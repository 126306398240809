import React from 'react'
import {Image, Row, Col} from 'react-bootstrap'
import './styles/SectionTwo.css'

const SectionTwo = () => {
  return (
    <div id="section-two-background">
      <div id="section-two" className="section">
        <Row>
          <Col md={12} className="title-container">
            <h1>How it Works</h1>
            <h5>
              Our goal is to match Vendors and Investors in put options
              <br />
              through a transparent site that reduces transaction costs.
            </h5>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="description-container">
            <Image className="description-img" src="/assets/icons/buyers.png" />
            <h5>Vendors</h5>
            <p>
              Vendors can list their requested put options on our site, and
              upload necessary documents. Vendors can also review other Vendors'
              requested terms.
            </p>
          </Col>
          <Col md={4} className="description-container">
            <Image src="/assets/icons/time.svg" />
            <h5>Save Time</h5>
            <p>
              We curate all put options requested, and only list marketable put
              options which will be presented to qualified Investors on our site.
            </p>
          </Col>
          <Col md={4} className="description-container">
            <Image src="/assets/icons/sellers.png" className="sellers-img" />
            <h5>Investors</h5>
            <p>
              Investors can review put options requested and select which put
              option they would like to accept on the requested terms, or
              counter offer at different terms. All requested put options are
              executable.
            </p>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default SectionTwo
