import React from 'react';
import { connect } from 'react-redux';
import { Image, Button } from 'react-bootstrap';

import './ListNewClaim.css';

function checkIfFieldsAreEmpty(claim) {
  for (let [key, value] of Object.entries(claim)) {
    if (key !== 'scp' && String(value).length === 0 && key !== 'alertMessage') {
      return false;
    }
  }
  return true;
}

class EditProfileForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      debtor: '',
      buyer: '',
      maxClaimAmount: '',
      term: '',
      purchaseRate: '',
      feeRate: '',
      expirationDate: '',
      adminFee: '',
      scp: true,
      alertMessage: ''
    };
    this.onFormValueChange = this.onFormValueChange.bind(this);
    this.onFormOptionChange = this.onFormOptionChange.bind(this);
  }

  onFormValueChange(event) {
    const target = event.target;
    let value = Number(target.value);
    const max = target.getAttribute('data-max');
    const min = target.getAttribute('data-min');
    if (max) {
      if (value > Number(max)) return;
    }
    if (min) {
      if (value < Number(min)) return;
    }
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  onFormOptionChange(event) {
    this.setState({
      scp: event.target.name === 'scp'
    });
  }

  submitNewClaimForm = async () => {
    let result;
    try {
      result = await fetch('/api/claim/listnewclaim', {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrer: 'no-referrer',
        body: JSON.stringify({
          account: this.props.account,
          claim: this.state
        })
      });
      result = await result.json();
    } catch (err) {
      result = { success: false };
    }
    if (result.success) {
      this.setState({
        alertMessage:
          'Successfully sent request of new claim. An administrator will get back to you soon.'
      });
    } else {
      this.setState({
        alertMessage: 'Something went wrong, please try again soon.'
      });
    }
  };

  render() {
    return (
      <form
        id="listNewClaim"
        onSubmit={ev => {
          ev.preventDefault();
          this.submitNewClaimForm();
        }}
      >
        <div className="card">
          <div className="card-header">
            <h5>LIST NEW CLAIM</h5>
            <div className="alert alert-info" role="alert">
              Purchase Rate, Fee Rate, Gross Margin, and Unsecured Claims
              requires decimal values (.25, etc.)
            </div>
          </div>
          <div className="card-body">
            <div className="input-container">
              <Image src="/assets/icons/flag.svg" />
              <input
                type="text"
                name="debtor"
                className="form-input"
                placeholder="CUSTOMER"
                value={this.state.debtor}
                onChange={this.onFormValueChange}
              />
            </div>
            <div className="input-container">
              <Image src="/assets/icons/flag.svg" />
              <input
                required
                autoComplete="off"
                type="text"
                name="buyer"
                className="form-input"
                placeholder="VENDOR"
                value={this.state.buyer}
                onChange={this.onFormValueChange}
              />
            </div>
            <div className="input-container">
              <Image src="/assets/icons/flag.svg" />
              <input
                required
                type="number"
                name="maxClaimAmount"
                className="form-input"
                placeholder="MAX. CLAIM AMOUNT"
                value={this.state.maxClaimAmount}
                onChange={this.onFormValueChange}
              />
            </div>
            <div className="input-container">
              <Image src="/assets/icons/flag.svg" />
              <input
                autoComplete="off"
                type="number"
                name="term"
                className="form-input"
                placeholder="TERM"
                value={this.state.term}
                onChange={this.onFormValueChange}
              />
            </div>
            <div className="input-container">
              <Image src="/assets/icons/flag.svg" />
              <input
                autoComplete="off"
                type="number"
                data-max="1"
                data-min="0"
                name="purchaseRate"
                className="form-input"
                placeholder="PURCHASE RATE"
                value={this.state.purchaseRate}
                onChange={this.onFormValueChange}
              />
            </div>
            <div className="input-container">
              <Image src="/assets/icons/flag.svg" />
              <input
                autoComplete="off"
                type="number"
                name="adminFee"
                className="form-input"
                placeholder="ADMIN. FEE"
                value={this.state.adminFee}
                onChange={this.onFormValueChange}
              />
            </div>
            <div className="input-container">
              <Image src="/assets/icons/flag.svg" />
              <input
                autoComplete="off"
                type="date"
                name="expirationDate"
                className="form-input"
                placeholder="EXPIRATION DATE"
                value={this.state.expirationDate}
                onChange={this.onFormValueChange}
              />
            </div>
            <div className="input-container">
              <Image src="/assets/icons/flag.svg" />
              <input
                autoComplete="off"
                type="number"
                name="feeRate"
                data-max="1"
                data-min="0"
                className="form-input"
                placeholder="FEE RATE"
                value={this.state.feeRate}
                onChange={this.onFormValueChange}
              />
            </div>
            <p className="scp">USE SCP:</p>
            <div className="options-container">
              <div className="form-check radio">
                <input
                  className="form-check-input"
                  type="radio"
                  name="scp"
                  checked={this.state.scp}
                  onChange={this.onFormOptionChange}
                />
                <label className="form-check-label" htmlFor="scp">
                  YES
                </label>
              </div>
              <div className="form-check radio">
                <input
                  className="form-check-input"
                  type="radio"
                  name="not-scp"
                  checked={!this.state.scp}
                  onChange={this.onFormOptionChange}
                />
                <label className="form-check-label" htmlFor="not-scp">
                  NO
                </label>
              </div>
            </div>
            <div className="card-footer">
              <Button
                type="submit"
                className="btn-default"
                disabled={!checkIfFieldsAreEmpty(this.state)}
              >
                SAVE
              </Button>
            </div>
            <div
              className="alert"
              style={{ display: this.state.alertMessage ? 'block' : 'none' }}
            >
              {this.state.alertMessage}
            </div>
          </div>
        </div>
      </form>
    );
  }
}

const mapState = state => {
  return {
    account: state.account
  };
};

export default connect(mapState)(EditProfileForm);
