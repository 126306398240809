import React from 'react'
import {connect} from 'react-redux'
import {Button} from 'react-bootstrap'
import axios from 'axios'
import Select from 'react-select'
import './AgreementPanelContent.css'

class AgreementPanelContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sacFile: '',
      redlineSacFile: '',
      buyersFile: '',
      sellersFile: '',
      scpFile: '',
      inAppMessage: '',
      selectedSeller: '',
      pricingFile: '',
      pricingFileName: '',
      selected: {value: '', label: ''},
      selectOptions: []
    }
    this.handleSellerSelect = this.handleSellerSelect.bind(this)
    this.handleFileUpload = this.handleFileUpload.bind(this)
    this.clearInAppMessage = this.clearInAppMessage.bind(this)
  }

  componentDidMount() {
    this.fetchOptions()
  }

  fetchOptions = () => {
    axios
      .get(`/api/pricing`)
      .then(response => {
        const selectOptions = response.data.map(el => ({
          value: el.name,
          label: el.name
        }))
        this.setState({
          selectOptions,
          selected: selectOptions[0]
        })
      })
      .catch(error => {
        console.log('error: ', error)
      })
  }

  handleSellerSelect = selectedSeller => {
    this.setState({
      selectedSeller: selectedSeller
    })
  }

  handleFileUpload = event => {
    this.setState({
      [event.target.name + 'File']: event.target.files
    })
  }

  clearInAppMessage = () => {
    setTimeout(() => {
      this.setState({
        inAppMessage: ''
      })
    }, 8000)
  }

  submitSacFile = event => {
    event.preventDefault()
    const formData = new FormData()
    formData.append('file', this.state.sacFile[0])
    axios
      .post(`/api/file/sac`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(() => {
        this.setState({
          inAppMessage: 'SAC is updated.'
        })
        this.sacInput.value = ''
        this.clearInAppMessage()
      })
      .catch(error => {
        this.setState({
          inAppMessage: 'Update Failed. Please try again.'
        })
        this.sacInput.value = ''
        this.clearInAppMessage()
      })
  }

  submitRedlineSacFile = event => {
    event.preventDefault()
    const sellerId = this.state.selectedSeller.value._id
    const formData = new FormData()
    formData.append('file', this.state.redlineSacFile[0])
    axios
      .post(`/api/file/redlineSac-${sellerId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(() => {
        this.setState({
          inAppMessage: 'Red-line changes to SAC is updated.'
        })
        this.redlineSacInput.value = ''
        this.clearInAppMessage()
      })
      .catch(error => {
        this.setState({
          inAppMessage: 'Update Failed. Please try again.'
        })
        this.sacInput.value = ''
        this.clearInAppMessage()
      })
  }

  submitBuyersFile = event => {
    event.preventDefault()
    const formData = new FormData()
    formData.append('file', this.state.buyersFile[0])
    axios
      .post(`/api/file/investorsAgreement`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(() => {
        this.setState({
          inAppMessage: 'Buyers Agreement is updated.'
        })
        this.buyersInput.value = ''
        this.clearInAppMessage()
      })
      .catch(error => {
        this.setState({
          inAppMessage: 'Update Failed. Please try again.'
        })
        this.buyersInput.value = ''
        this.clearInAppMessage()
      })
  }

  submitSellersFile = event => {
    event.preventDefault()
    const formData = new FormData()
    formData.append('file', this.state.sellersFile[0])
    axios
      .post(`/api/file/vendorsAgreement`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(() => {
        this.setState({
          inAppMessage: 'Sellers Agreement is updated'
        })
        this.sellersInput.value = ''
        this.clearInAppMessage()
      })
      .catch(error => {
        this.setState({
          inAppMessage: 'Update Failed. Please try again.'
        })
        this.sellersInput.value = ''
        this.clearInAppMessage()
      })
  }

  submitSCPFile = event => {
    event.preventDefault()
    const formData = new FormData()
    formData.append('file', this.state.scpFile[0])
    axios
      .post(`/api/file/scpAgreement`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(() => {
        this.setState({
          inAppMessage: 'SCP Agreement is updated'
        })
        this.scpInput.value = ''
        this.clearInAppMessage()
      })
      .catch(error => {
        this.setState({
          inAppMessage: 'Update Failed. Please try again.'
        })
        this.scpInput.value = ''
        this.clearInAppMessage()
      })
  }

  submitPricingFile = event => {
    event.preventDefault()
    const formData = new FormData()
    formData.append('file', this.state.pricingFile[0])
    axios
      .post(
        `/api/pricing/${this.state.pricingFileName.replace(' ', '_')}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      .then(() => {
        this.setState({
          inAppMessage: 'The file is uploaded',
          pricingFileName: ''
        })
        this.pricingInput.value = ''
        this.clearInAppMessage()
        this.fetchOptions()
      })
      .catch(error => {
        this.setState({
          inAppMessage: 'Update Failed. Please try again.'
        })
        this.pricingInput.value = ''
        this.clearInAppMessage()
      })
  }

  deletePricing = event => {
    event.preventDefault()
    axios
      .delete(`/api/pricing/${this.state.selected.value.replace(' ', '_')}`)
      .then(() => {
        this.setState({
          inAppMessage: 'The file is deleted'
        })
        this.clearInAppMessage()
        this.fetchOptions()
      })
      .catch(error => {
        this.setState({
          inAppMessage: 'Update Failed. Please try again.'
        })
        this.clearInAppMessage()
      })
  }

  handlePricingNameChnage = event => {
    event.preventDefault()
    this.setState({
      pricingFileName: event.target.value
    })
  }

  render() {
    const sellers = this.props.users
      .filter(user => user.accountType === 'seller')
      .map(user => ({value: user, label: user.company}))
    const selectStyle = {
      container: provided => ({
        ...provided,
        width: 360,
        height: 45,
        margin: '0 auto'
      })
    }

    return (
      <div id="agree-panel-content">
        {this.state.inAppMessage && (
          <div className="inAppMessage">{this.state.inAppMessage}</div>
        )}
        <h6>SAC</h6>
        <Button
          className="btn-default"
          onClick={() =>
            window.open(
              `https://s3.us-east-2.amazonaws.com/claims-put-market-assets/sac.pdf`,
              '_blank'
            )
          }
        >
          View SAC
        </Button>
        <form className="file-container" onSubmit={this.submitSacFile}>
          <input
            type="file"
            name="sac"
            onChange={this.handleFileUpload}
            ref={ref => {
              this.sacInput = ref
            }}
          />
          <button type="submit">Update</button>
        </form>
        <h6>SCP</h6>
        <Button
          className="btn-default"
          onClick={() =>
            window.open(
              `https://s3.us-east-2.amazonaws.com/claims-put-market-assets/scpAgreement.pdf`,
              '_blank'
            )
          }
        >
          View SCP
        </Button>
        <form className="file-container" onSubmit={this.submitSCPFile}>
          <input
            type="file"
            name="scp"
            onChange={this.handleFileUpload}
            ref={ref => {
              this.scpInput = ref
            }}
          />
          <button type="submit">Update</button>
        </form>
        <h6>RED-LINE CHANGES TO SCP</h6>
        <p>Select Vendor: </p>
        <Select
          styles={selectStyle}
          value={this.state.selectedSeller}
          onChange={this.handleSellerSelect}
          options={sellers}
        />
        {this.state.selectedSeller &&
        this.state.selectedSeller.value.hasRedlineSac ? (
          <Button
            className="btn-default"
            onClick={() =>
              window.open(
                `https://s3.us-east-2.amazonaws.com/claims-put-market-assets/redlineSac-${
                  this.state.selectedSeller.value._id
                }.${
                  this.state.selectedSeller.value.redlineSacExt
                    ? this.state.selectedSeller.value.redlineSacExt
                    : 'pdf'
                }`,
                '_blank'
              )
            }
          >
            View red-line changes to SCP
          </Button>
        ) : (
          <Button className="btn-default">No red-line changes to SCP</Button>
        )}
        <form className="file-container" onSubmit={this.submitRedlineSacFile}>
          <input
            type="file"
            name="redlineSac"
            onChange={this.handleFileUpload}
            ref={ref => {
              this.redlineSacInput = ref
            }}
          />
          <button type="submit">Update</button>
        </form>
        <h6>INVESTORS AGREEMENT</h6>
        <Button
          className="btn-default"
          onClick={() =>
            window.open(
              `https://s3.us-east-2.amazonaws.com/claims-put-market-assets/buyersAgreement.pdf`,
              '_blank'
            )
          }
        >
          View Investors Agreement
        </Button>
        <form className="file-container" onSubmit={this.submitBuyersFile}>
          <input
            type="file"
            name="buyers"
            onChange={this.handleFileUpload}
            ref={ref => {
              this.buyersInput = ref
            }}
          />
          <button type="submit">Update</button>
        </form>
        <h6>VENDORS AGREEMENT</h6>
        <Button
          className="btn-default"
          onClick={() =>
            window.open(
              `https://s3.us-east-2.amazonaws.com/claims-put-market-assets/vendorsAgreement.pdf`,
              '_blank'
            )
          }
        >
          View Vendors Agreement
        </Button>
        <form className="file-container" onSubmit={this.submitSellersFile}>
          <input
            type="file"
            name="sellers"
            onChange={this.handleFileUpload}
            ref={ref => {
              this.sellersInput = ref
            }}
          />
          <button type="submit">Update</button>
        </form>
        <h6>PUTQUOTE PRICING</h6>
        <p className="subtitle">Select File to Delete</p>
        <Select
          options={this.state.selectOptions}
          onChange={option => {
            this.setState({selected: option})
          }}
          value={this.state.selected}
          className="dropdown"
        />
        <button
          type="submit"
          className="delete-btn"
          onClick={this.deletePricing}
        >
          Delete
        </button>
        <p className="subtitle">Upload New File</p>
        <form className="file-container" onSubmit={this.submitPricingFile}>
          <input
            type="text"
            name="fileName"
            placeholder="File Name"
            value={this.state.pricingFileName}
            onChange={this.handlePricingNameChnage}
          />
          <input
            type="file"
            name="pricing"
            onChange={this.handleFileUpload}
            ref={ref => {
              this.pricingInput = ref
            }}
          />
          <button type="submit">Upload</button>
        </form>
      </div>
    )
  }
}

const mapState = state => {
  return {
    account: state.account,
    claims: state.claims,
    users: state.users
  }
}

const mapDispatch = dispatch => {
  return {}
}

export default connect(mapState, mapDispatch)(AgreementPanelContent)
