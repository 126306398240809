import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import * as Scroll from 'react-scroll';

import history from '../../history';
import Header from '../Header';
import EditProfileForm from '../EditProfileForm';
import ClaimItem from '../ClaimItem';
import ListNewClaim from '../ListNewClaim';
import { editAccount, logOut } from '../../store';

import './Profile.css';

const animateScroll = Scroll.animateScroll;

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.account.name,
      company: this.props.account.company,
      phone: this.props.account.phone,
      email: this.props.account.email,
      accountType: this.props.account.accountType,
      password: '',
      passwordConfirm: '',
      changePasswordOpen: false,
      passwordNotification: false
    };
    this.onFormOptionChange = this.onFormOptionChange.bind(this);
    this.onFormValueChange = this.onFormValueChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.createUserClaimJSX = this.createUserClaimJSX.bind(this);
    this.changePasswordToggle = this.changePasswordToggle.bind(this);
  }

  componentDidMount() {
    animateScroll.scrollTo(0, { smooth: true, duration: 500 });
  }

  onFormOptionChange(event) {
    this.setState({
      accountType: event.target.dataset.name
    });
  }

  onFormValueChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  onFormSubmit(event) {
    event.preventDefault();
    if (this.state.password !== this.state.passwordConfirm) {
      this.setState({ passwordNotification: true });
      return;
    }
    this.props.submitEditForm(this.props.account.id, this.state);
  }

  createUserClaimJSX(claims) {
    const claimItems = claims.map((item, index) => (
      <ClaimItem key={item._id} num={index + 1} item={item} />
    ));
    return <tbody>{claimItems}</tbody>;
  }

  changePasswordToggle() {
    const changePasswordOpen = this.state.changePasswordOpen;
    this.setState({ changePasswordOpen: !changePasswordOpen });
  }

  render() {
    const userClaimsSet = new Set(this.props.account.claims);
    const userClaims = this.props.claims.filter(el =>
      userClaimsSet.has(el._id)
    );
    const claimJSX = this.createUserClaimJSX(userClaims);

    return (
      <div>
        <Header />
        <div id="profile" className="section">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="btn-container">
                  <div className="action-btn-container">
                    {this.props.account.isAdmin && (
                      <Button
                        className="btn-default"
                        style={{ backgroundColor: '#F5B041' }}
                        onClick={() => history.push('/admin')}
                      >
                        ADMIN PANEL
                      </Button>
                    )}
                  </div>
                  <Button
                    className="btn-default logout-btn"
                    onClick={this.props.userLogOut}
                  >
                    Log out
                  </Button>
                </div>
                {!this.props.account.agreementSigned && (
                  <p className="text-center" style={{ color: 'red' }}>
                    STEP THREE: Sign the Agreement sent to your email to unlock
                    the full website.
                    <br />
                    Didn't receive an agreement?
                    <br />
                    <a href="/agreement">
                      Click here to resend via email (This will invalidate any
                      previous agreements sent)
                    </a>
                    <br />
                    Already signed?
                    <br />
                    <a href="/">Click here to refresh and view claims</a>
                  </p>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col">
                <h5 className="text-center">ACCOUNT SETTINGS</h5>
                <EditProfileForm
                  title="EDIT PROFILE"
                  value={this.state}
                  canChangeType={false}
                  onFormOptionChange={this.onFormOptionChange}
                  onFormValueChange={this.onFormValueChange}
                  onFormSubmit={this.onFormSubmit}
                  changePasswordToggle={this.changePasswordToggle}
                />
                {this.props.account.accountType === 'seller' &&
                  this.props.account.agreementSigned && <ListNewClaim />}
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="card">
                  <div className="card-header text-center">
                    <div className="claims-list">
                      <h5 className="claims-title">PUT OPTIONS</h5>
                    </div>
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        {/* caption is for screen readers, describes the table */}
                        <caption>Lists all put options</caption>
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">CASE</th>
                            <th scope="col">VENDOR</th>
                            <th scope="col">DOCUMENTS</th>
                          </tr>
                        </thead>
                        {claimJSX}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapState = state => {
  return {
    account: state.account,
    claims: state.claims
  };
};

const mapDispatch = dispatch => {
  return {
    submitEditForm(userId, userInfo) {
      dispatch(editAccount(userId, userInfo));
    },
    userLogOut() {
      dispatch(logOut());
    }
  };
};

export default connect(mapState, mapDispatch)(Profile);
