import React from 'react'
import {Row, Col} from 'react-bootstrap'
import moment from 'moment'
import {
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  Legend,
  CartesianGrid
} from 'recharts'
import Header from '../Header'
import * as Scroll from 'react-scroll'
import Select from 'react-select'
import axios from 'axios'

import './CDS.css'

const animateScroll = Scroll.animateScroll

export default class CDSPricing extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: {value: '', label: ''},
      selectOptions: [],
      data: []
    }
  }

  componentDidMount() {
    let dataType;
    const props = this.props
    if (props.location && props.location.state && props.location.state.data) {
      dataType = this.props.location.state.data
    }
    animateScroll.scrollTo(0, {smooth: true, duration: 500})
    axios
      .get(`/api/pricing`)
      .then(response => {
        const data = normalizeData(response.data)
        const selectOptions = response.data.map(el => ({
          value: el.name,
          label: el.name
        }));
        if (!dataType) {
          dataType = selectOptions[0];
        }
        this.setState({
          selectOptions,
          selected: dataType,
          data
        })
      })
      .catch(error => {
        console.log('error: ', error)
      })
  }

  componentDidUpdate(prevProps) {
    const prevLocation = prevProps.location
    const location = this.props.location
    if (prevLocation && location) {
      const prevState = prevLocation.state
      const state = location.state
      if (prevState && state) {
        const prevData = prevState.data
        const data = state.data
        if (prevData !== data) {
          this.setState(previousState => ({
            selected: data
          }))
        }
      }
    }
  }

  render() {
    const rawData = this.state.data.find(el => el.name === this.state.selected.value);
    let data = rawData && rawData.data;
    if (data) {
      data = data.slice(0).reverse();
    }
    const colourStyles = {
      dropdownIndicator: styles => ({ ...styles, backgroundColor: '#EE6F2D' })
    }  
    return (
      <div id="cds-chart">
        <Header />
        <div className="cds-chart-header">
          <div className="shade">
            <h1>PutQuote Pricing Chart</h1>
          </div>
        </div>
        <div className="cds-chart-page container">
          
          <Select
            options={this.state.selectOptions}
            styles={colourStyles}
            onChange={option => {
              this.setState({selected: option})
            }}
            value={this.state.selected}
            className="dropdown"
          />
          <Row className="chart-section">
            <Col md={12}>
              <h3>{this.state.selected.label}</h3>
            </Col>
            <ResponsiveContainer
              width="100%"
              aspect={1.5}
              maxHeight={480}
              minWidth={300}
            >
              <ComposedChart
                data={data}
                margin={{top: 5, right: 20, bottom: 5, left: 0}}
              >
                <Line
                  type="monotone"
                  dataKey="Price"
                  yAxisId="left"
                  stroke="#EE6F2D"
                  strokeWidth={2}
                  dot={false}
                />
                {/* <Line
                  type="monotone"
                  dataKey="Volume"
                  yAxisId="right"
                  stroke="#f0ac89"
                  strokeWidth={2}
                  dot={false}
                /> */}
                <XAxis
                  dataKey="Date"
                  tickFormatter={tickItem =>
                    moment(tickItem).format('MM/DD/YY')
                  }
                  angle={295}
                  dy={30}
                  dx={-15}
                  height={80}
                />
                <YAxis
                  tickFormatter={tickItem => tickItem.toFixed(3)}
                  label={{
                    value: 'Prices (per month)',
                    angle: 270,
                    position: 'insideLeft',
                    offset: 10
                  }}
                  width={100}
                  yAxisId="left"
                  unit="%"
                />
                {/* <YAxis
                  label={{
                    value: 'Volume (per month)',
                    angle: 90,
                    position: 'insideRight',
                    offset: 10
                  }}
                  width={100}
                  orientation='right' 
                  yAxisId="right"
                /> */}
                <Legend /> 
                <CartesianGrid stroke='#f5f5f5' vertical={false} /> 
                <Tooltip
                  formatter={(value, label) => {
                    if (label == "Price") {
                      return [`${value}%`, `${label} (per month)`]
                    }
                    if (label == "Volume") {
                      return [`${value}`, `${label} (per month)`]
                    }
                  }}
                  labelFormatter={date => moment(date).format('YYYY-MM-DD')}
                />
              </ComposedChart>
            </ResponsiveContainer>
          </Row>
        </div>
      </div>
    )
  }
}

function normalizeData(data) {
  return data.map(el => {
    const info = el.data.map(d => ({
      Date: d['Date'],
      Price: d['Price'],
      // Volume: parseInt(d['Volume'])
    }))
    return {
      name: el.name,
      data: info
    }
  })
}
