import React, { Component } from 'react';

import Card from './Card/Card';
import NewTransaction from './NewTransaction/NewTransaction';

import './TransactionsPanelContent.css';

function sortByDate(transactions) {
  return transactions.sort(function(a, b) {
    return new Date(b.transactionDate) - new Date(a.transactionDate);
  });
}

export default class TransactionsPanelContent extends Component {
  state = {
    transactions: [],
    btnsDisabled: false
  };

  async componentDidMount() {
    let transactions = await fetch('/api/transaction');
    try {
      transactions = await transactions.json();
    } catch (err) {
      console.log('error getting transactions', err);
    }
    transactions = sortByDate(transactions);

    this.setState({ transactions });
  }

  update = async update => {
    let updatedTransaction = await fetch('/api/transaction/update', {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({ update, id: update._id })
    });
    try {
      updatedTransaction = await updatedTransaction.json();
    } catch (err) {
      console.log('error updating transaction', err);
    }

    const filteredTransactions = this.state.transactions.filter(
      currentTransaction => currentTransaction._id !== update._id
    );
    const transactions = sortByDate([...filteredTransactions, update]);
    this.setState({ transactions, btnsDisabled: false });
  };

  delete = async transaction => {
    const check = confirm('Are you sure you want to delete this?');
    if (!check) {
      return;
    }
    let deletedTransaction = await fetch('/api/transaction/delete', {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({ id: transaction._id })
    });
    try {
      deletedTransaction = await deletedTransaction.json();
    } catch (err) {
      console.log('error deleting transaction', err);
    }

    this.setState(prevState => ({
      transactions: prevState.transactions.filter(
        currentTransaction => currentTransaction._id !== transaction._id
      )
    }));
  };

  post = async transaction => {
    let postedTransaction = await fetch('/api/transaction', {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({ transaction })
    });
    try {
      postedTransaction = await postedTransaction.json();
    } catch (err) {
      console.log('error posting transaction', err);
    }

    this.setState(prevState => ({
      transactions: [...prevState.transactions, transaction]
    }));
  };

  createCards = () => {
    return this.state.transactions.map(transaction => {
      let id = transaction._id;
      const maximum = 999999999999999;
      const minimum = 111111111111111;
      if (id === undefined)
        id = Math.floor(Math.random() * (maximum - minimum + 1)) + minimum;

      return (
        <Card
          transaction={transaction}
          delete={this.delete}
          update={this.update}
          btnsDisabled={this.state.btnsDisabled}
          key={id}
        />
      );
    });
  };

  render() {
    return (
      <div id="panel-content" className="transaction-panel">
        <div className="accordion">
          {this.state.transactions.length > 0 && this.createCards()}
          <NewTransaction
            post={this.post}
            btnsDisabled={this.state.btnsDisabled}
          />
        </div>
      </div>
    );
  }
}
