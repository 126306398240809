import React from 'react';

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function convertDecimal(decimal) {
  return String(Number.parseFloat(Number(decimal) * 100).toFixed(2));
}

export function convertMaxClaim(decimal) {
  return String((Number(decimal)).toFixed(2));
}

export function calculateFee(maxClaimAmount, term, feeRate, returnValue) {
  let result;
  try {
    result = (Number(maxClaimAmount) * Number(term) * Number(feeRate)).toFixed(
      0
    );
  } catch (error) {
    return 'Invalid Number';
  }
  if (returnValue) return result;
  if (result < 0) {
    return `-$${numberWithCommas(result)}`;
  } else {
    return `$${numberWithCommas(result)}`;
  }
}

export function calculateLoss(
  maxClaimAmount,
  purchaseRate,
  returnValue,
  scp,
  span
) {
  let result;
  try {
    result = Number(maxClaimAmount) * Number(purchaseRate - 1);
  } catch (error) {
    return 'Invalid Number';
  }
  if (returnValue) return result;
  if (result < 0) {
    result = `-$${numberWithCommas(Math.abs(result.toFixed(0)))}`;
  } else {
    result = `$${Math.abs(numberWithCommas(result.toFixed(0)))}`;
  }
  if (scp) {
    if (span) {
      return (
        <span className="red">
          {`${result} ($${numberWithCommas(maxClaimAmount)} x [${convertDecimal(
            purchaseRate
          )}% Rate - 100%])`}
        </span>
      );
    } else {
      return `${result} ($${numberWithCommas(
        maxClaimAmount
      )} x [${convertDecimal(purchaseRate)}% Rate - 100%])`;
    }
  } else {
    if (span) {
      return (
        <span className="red">
          {`${result} ($${numberWithCommas(maxClaimAmount)} x [${convertDecimal(
            purchaseRate
          )}% value - 100%])`}
        </span>
      );
    } else {
      return `${result} ($${numberWithCommas(
        maxClaimAmount
      )} x [${convertDecimal(purchaseRate)}% value - 100%])`;
    }
  }
}

export function calculateProfit(fee, lossOnClaim, returnValue) {
  if (fee === 'Invalid Number' || lossOnClaim === 'Invalid Number') {
    return 'Invalid Number';
  }
  let result;
  try {
    result = fee + lossOnClaim;
  } catch (error) {
    return 'Invalid Number';
  }
  if (returnValue) return result;
  if (result < 0) {
    return `-$${numberWithCommas(result.toFixed(0))}`;
  } else {
    return `$${numberWithCommas(result.toFixed(0))}`;
  }
}

export function calculateBenefit(scp, withoutScp) {
  if (scp === 'Invalid Number' || withoutScp === 'Invalid Number') {
    return 'Invalid Number';
  }
  let result;
  try {
    result = scp - withoutScp;
  } catch (error) {
    return 'Invalid Number';
  }
  return `$${numberWithCommas(result.toFixed(0))} benefit with SCP`;
}

export function createListGroupItem(label, content) {
  return (
    <li className="list-group-item">
      <span className="left">{label}</span>{' '}
      <span className="right">{content}</span>
    </li>
  );
}
