import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Image } from 'react-bootstrap';

import history from '../../../history';
import { selectedDebtor, setSelectedSeller } from '../../../store';

import './styles/SectionOne.css';
import {
  numberWithCommas,
  convertDecimal,
  convertMaxClaim
} from '../../CreateNewClaim/Steps/utils';

class SectionOne extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formError: '',
      alert: false,
      currentTab: 'customer',
      selectedCustomer: ''
    };
    this.toSelectSeller = this.toSelectSeller.bind(this);
  }

  componentDidMount() {
    if (this.props.claims && this.props.claims[0]) {
      this.setState({
        selectedDebtor: this.props.claims[0].debtor
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.claims !== this.props.claims) {
      if (this.props.claims && this.props.claims[0]) {
        this.setState(
          {
            selectedDebtor: this.props.claims[0].debtor
          },
          () => {
            // uncheck all radio buttons
            const radioBtns = document.querySelectorAll('.form-check input');
            radioBtns.forEach(btn => {
              btn.checked = false;
            });
          }
        );
      }
    }
  }

  toSelectDebtor = () =>
    this.setState({
      currentTab: 'customer'
    });

  toSelectSeller() {
    if (!this.state.selectedDebtor) {
      this.setState({
        formError: 'Please Select Lead Debtor'
      });
    }
  }

  handleCustomerClick = event =>
    this.setState({
      currentTab: 'vendor',
      selectedCustomer: event.target.dataset.name
    });

  handleVendorClick = event => {
    const claim = this.props.claims.filter(
      ({ buyer }) => buyer === event.target.dataset.name
    )[0];
    if (this.props.account && this.props.account.id) {
      history.push('/createclaim', { claim: claim._id });
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    } else {
      this.setState({ alert: true });
    }
  };

  createCustomerOptions = claims => {
    const hash = {};
    const filteredClaims = claims.filter(({ debtor }) => {
      if (!hash[debtor]) {
        hash[debtor] = true;
        return true;
      }
      return false;
    });
    return filteredClaims.map(({ debtor, _id }) => (
      <div
        key={_id}
        className="list-group-item text-center debtor-option"
        onClick={this.handleCustomerClick}
        data-name={debtor}
      >
        {debtor}
      </div>
    ));
  };

  createVendorOptions = claims => {
    const customerClaims = claims.filter(
      ({ debtor }) => debtor === this.state.selectedCustomer
    );
    return customerClaims.map(({ buyer, maxClaimAmount, feeRate, _id }) => (
      <div
        key={_id}
        className="list-group-item text-center seller-option"
        data-name={buyer}
        onClick={this.handleVendorClick}
      >
        {`$${numberWithCommas(convertMaxClaim(maxClaimAmount))} bids ${convertDecimal(
          feeRate
        )}%`}
      </div>
    ));
  };

  getAvg = claims => {
    const total = claims.reduce((acc, cur) => acc + Number(cur.minAmount), 0);
    const consideration = claims.reduce(
      (acc, cur) => acc + Number(cur.minAmount) * Number(cur.purchaseRate),
      0
    );
    return { minAmount: total / claims.length, rate: consideration / total };
  };

  render() {
    return (
      <div id="section-one-background">
        <div id="section-one" className="section">
          <Row>
            <Col md={6}>
              <div className="text-section-container">
                <div>
                  <h1>Simplified Transparent Platform</h1>
                  <p>
                    Claims Put Market is a new platform dedicated to simplifying
                    the market for put options on potential bankruptcy claims.
                  </p>
                </div>
                <Row>
                  <Col md={6}>
                    <h5>STREAMLINED PROCESS</h5>
                    <p>
                      We streamline the process of buying and selling put
                      options by providing a simplified and transparent market
                      structure.
                    </p>
                  </Col>
                  <Col md={6}>
                    <h5>SAVINGS</h5>
                    <p>
                      We save you money on legal fees by using a Simple Claims
                      Put ("SCP") agreement that is fair to both Vendors and
                      Investors.
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6} className="onboarding-form-container">
              <div className="onboarding-form">
                {this.state.formError && (
                  <p style={{ color: 'red' }}>{this.state.formError}</p>
                )}
                {this.state.currentTab === 'customer' ? (
                  <div className="card">
                    <div className="card-header text-center">
                      <h5>
                        <strong>SELECT CUSTOMER</strong>
                      </h5>
                    </div>
                    {!this.state.alert && (
                      <ul className="list-group list-group-flush list-one">
                        {this.createCustomerOptions(this.props.claims)}
                      </ul>
                    )}
                  </div>
                ) : (
                  <div className="card">
                    <div className="card-header text-center">
                      <h5>
                        <strong>SELECT VENDOR</strong>
                      </h5>
                    </div>
                    <ul className="list-group list-group-flush list-two">
                      {this.props.account && this.props.claims
                        ? this.props.account.accountType
                          ? this.createVendorOptions(this.props.claims)
                          : 'Please sign up to view the list of vendors.'
                        : null}
                    </ul>
                    <div
                      className="back-btn mx-auto"
                      onClick={this.toSelectDebtor}
                    >
                      <Image src="/assets/icons/arrow-left.svg" />
                      Go Back
                    </div>
                  </div>
                )}
                {this.state.alert && (
                  <div className="alert alert-warning" role="alert">
                    You must be logged in to trade claims.
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapState = state => {
  return {
    account: state.account,
    claims: state.claims
  };
};

export default connect(mapState)(SectionOne);
