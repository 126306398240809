import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import AdminClaimItem from '../AdminClaimItem';
import AddClaimForm from '../AddClaimForm';
import { putClaimInfo } from '../../store';

import './ClaimPanelContent.css';

class ClaimPanelContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddForm: false,
      updateForm: false,
      tabOpen: '',
      claims: []
    };
    this.addItemClick = this.addItemClick.bind(this);
  }

  async componentDidMount() {
    let claims = [];
    let result;
    try {
      claims = await fetch('/api/claim', {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrer: 'no-referrer'
      });
      claims = await claims.json();
    } catch (err) {
      result = { success: false };
    }
    this.setState({ claims });
  }

  addItemClick() {
    this.setState(prevState => ({
      showAddForm: !prevState.showAddForm
    }));
  }

  updateClaimAmount = claim => {
    this.setState(prevState => ({ claims: prevState.claims.concat(claim) }));
  };

  deleteClaim = claim => {
    this.setState(prevState => ({
      claims: prevState.claims.filter(
        currentClaim => currentClaim._id !== claim._id
      )
    }));
  };

  closeForm = () => {
    this.setState({ showAddForm: false });
  };

  handleTabChange = id => {
    this.setState({ tabOpen: id });
  };

  render() {
    return (
      <div id="panel-content">
        <React.Fragment>
          <Button
            className="btn-default addItemBtn"
            onClick={this.addItemClick}
          >{`Add Claim ${this.state.showAddForm ? '-' : '+'}`}</Button>
          {this.state.showAddForm && (
            <AddClaimForm
              updateClaimAmount={this.updateClaimAmount}
              updateForm={this.state.updateForm}
              closeForm={this.closeForm}
            />
          )}
        </React.Fragment>
        {this.state.claims.map(item => {
          return (
            <AdminClaimItem
              tabOpen={this.state.tabOpen}
              handleTabChange={this.handleTabChange}
              key={item._id}
              info={item}
              deleteClaim={this.deleteClaim}
              deleteItemSubmit={this.props.deleteItemSubmit}
              editItemFormSubmit={this.props.editItemFormSubmit}
            />
          );
        })}
      </div>
    );
  }
}

const mapState = state => {
  return {
    account: state.account,
    users: state.users
  };
};

const mapDispatch = dispatch => {
  return {
    editItemFormSubmit(id, form) {
      dispatch(putClaimInfo(id, form));
    }
  };
};

export default connect(mapState, mapDispatch)(ClaimPanelContent);
