import axios from 'axios'
import history from '../history'
import {getAllUsers, fetchAllUsers} from './users'
/**
 * ACTION TYPES
 */

const GET_USER = 'GET_USER'
const REMOVE_USER = 'REMOVE_USER'

/**
 * INITIAL STATE
 */
const defaultUser = {}

/**
 * ACTION CREATORS
 */
export const getUser = user => ({type: GET_USER, user})
const removeUser = () => ({type: REMOVE_USER})

/**
 * THUNK CREATORS
 */
export const fetchUser = () => async dispatch => {
  try {
    const res = await axios.get('/auth')
    dispatch(getUser(res.data || defaultUser))
  } catch (err) {}
}

export const logIn = userInfo => async dispatch => {
  try {
    const res = await axios.post(`/auth/login`, userInfo)
    dispatch(getUser(res.data))
  } catch (signInError) {
    return dispatch(getUser({error: signInError}))
  }
}

export const signUp = (userInfo, isAdmin) => async dispatch => {
  try {
    const res = await axios.post(`/auth/signUp`, userInfo)
    if (isAdmin) {
      dispatch(fetchAllUsers())
    } else {
      dispatch(getUser(res.data))
      history.push('/agreement')
    }
  } catch (signUpError) {
    return dispatch(getUser({error: signUpError}))
  }
}

export const deleteAccount = userId => async dispatch => {
  try {
    const res = await axios.delete(`/api/account/${userId}`)
  } catch (signInError) {
    return dispatch(getUser({error: signInError}))
  }

  try {
    const res = await axios.get('/api/account')
    dispatch(getAllUsers(res.data || []))
  } catch (err) {}
}

export const editAccount = (userId, userInfo, isAdmin) => async dispatch => {
  let res
  try {
    res = await axios.put(`/api/account/${userId}`, userInfo)
    if (isAdmin) dispatch(fetchAllUsers())
    else dispatch(getUser(res.data))
  } catch (signInError) {
    return dispatch(getUser({error: signInError}))
  }
}

export const logOut = () => async dispatch => {
  try {
    await axios.post('/auth/logout')
    dispatch(removeUser())
    history.push('/')
  } catch (err) {}
}

/**
 * REDUCER
 */
export default function(state = defaultUser, action) {
  switch (action.type) {
    case GET_USER:
      return action.user
    case REMOVE_USER:
      return defaultUser
    default:
      return state
  }
}
