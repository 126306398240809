import React from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import history from '../../history'
import {Button, Image} from 'react-bootstrap'
import * as Scroll from 'react-scroll'
import axios from 'axios'

import './Header.css'

const animateScroll = Scroll.animateScroll

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      userTabOption: false,
      selectOptions: [],
    }
    this.onUserOptionClick = this.onUserOptionClick.bind(this)
  }

  componentDidMount() {
    axios
      .get(`/api/pricing`)
      .then(response => {
        const selectOptions = response.data.map(el => ({
          value: el.name,
          label: el.name
        }))
        this.setState({
          selectOptions: selectOptions
        })
      })
      .catch(error => {
        console.log('error: ', error)
      })
  }

  checkLocation(position) {
    var oldIsHome =
      history.location.pathname === '/' || history.location.pathname === '/home'

    if (oldIsHome === false) {
      history.push('/')
      animateScroll.scrollTo(0, {smooth: true, duration: 500})
    }

    this.forceUpdate()

    setTimeout(function() {
      var destination = document.querySelector('#' + position)
      const elementPosition = destination.offsetTop - 30
      animateScroll.scrollTo(elementPosition, {smooth: true, duration: 500})
    }, 200)

    // close the navbar toggle when a link is clicked, by using click event
    if (window.innerWidth <= 992) {
      $('.navbar-toggler').click()
    }
  }

  render() {
    return (
      <nav id="header" className="navbar navbar-expand-lg navbar-dark">
        <Link className="navbar-brand brand-container" to="/">
          <Image src="/assets/images/logo-one-orange-resized.png" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto content-section">
            <li className="nav-item active">
              <a
                className="nav-link"
                onClick={() => this.checkLocation('section-two')}
              >
                How it Works
              </a>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                role="button"
                id="dropdown-agreement"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Agreements
              </a>
              <ul className="dropdown-menu" aria-labelledby="dropdownAgreement">

                <li className="dropdown-item">
                  <a
                    onClick={() => {
                      this.checkLocation('section-three-background')
                    }}
                  >
                    Simple Claims Put
                  </a>
                </li>
                <li className="dropdown-item">
                  <a
                    onClick={() => {
                      this.checkLocation('section-five-background')
                    }}
                  >
                    Vendor's Agreement
                  </a>
                </li>
                <li className="dropdown-item">
                  <a
                    onClick={() => {
                      this.checkLocation('section-four-background')
                    }}
                  >
                    Investor's Agreement
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item cds-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                role="button"
                id="dropdown-agreement"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                PutQuote
              </a>
              <ul className="dropdown-menu">
                <li className="dropdown-item">
                  <a
                    onClick={() => {
                      this.checkLocation('cds-background')
                    }}
                  >
                    About PutQuote
                  </a>
                </li>

                  {this.state.selectOptions.map((item, i) => {
                    return (
                      <li key={i} className="dropdown-item">
                        <a
                          onClick={() => {
                            history.push({
                              pathname: '/putquote',
                              state: {data: item}
                            })
                          }}
                        >
                          {item.label}
                        </a>
                      </li>
                    )
                })}
              </ul>
            </li>
            <li className="nav-item">
              <Link to="/transactions" className="nav-link">
                Transactions
              </Link>
            </li>
            <li className="nav-item">
              <a
                  className="nav-link"
                  onClick={() => this.checkLocation('broker')}>
                Brokers
              </a>
            </li>
            <li className="nav-item">
              <Link to="/faq" className="nav-link">
                FAQ
              </Link>
            </li>
            {this.props.account.name
              ? this.renderUserOption(this.props.account.name)
              : this.renderOnboarding()}
          </ul>
        </div>
      </nav>
    )
  }

  onUserOptionClick(event) {
    event.preventDefault()
    this.setState(prevState => ({
      userTabOption: !prevState.userTabOption
    }))
  }

  renderUserOption(name) {
    const initial = name[0].toUpperCase()
    return (
      <li className="nav-item">
        <Link className="nav-link" to="/profile">
          <div className="user-btn-container">
            <div className="userIcon">{initial}</div>
            <p>{name}</p>
          </div>
        </Link>
      </li>
    )
  }

  renderOnboarding() {
    return (
      <li className="nav-item onboarding-btn-container">
        <Button className="btn-default" href="/signup">
          Sign Up
        </Button>
        &nbsp;&nbsp;<span>or</span>
        <Link className="nav-link" to="/signin">
          Log In
        </Link>
      </li>
    )
  }
}

const mapState = state => {
  return {
    account: state.account
  }
}

export default connect(mapState)(Header)
