const SET_SELECTED_ACCOUNT_DEBTOR = 'SET_SELECTED_ACCOUNT_DEBTOR'

/**
 * ACTION CREATORS
 */
export const setSelectedAccountDebtor = debtor => ({
  type: SET_SELECTED_ACCOUNT_DEBTOR,
  debtor
})

/**
 * REDUCER
 */
export default function(state = '', action) {
  switch (action.type) {
    case SET_SELECTED_ACCOUNT_DEBTOR:
      return action.debtor
    default:
      return state
  }
}
