import React, {Component} from 'react'
import {Link} from 'react-router-dom'

import Header from '../OnboardingHeader'

import './ChangePassword.css'

/**
 * This function checks if the user has the correct password token
 * when they first get onto the page
 */
async function checkIfValidToken() {
  const pathname = window.location.pathname
  const tokenStartIdx = pathname.lastIndexOf('/') + 1
  const token = pathname.slice(tokenStartIdx, pathname.length)
  let isTokenValid = {valid: false}
  try {
    isTokenValid = await fetch(`/api/account/forgot-password/${token}`, {
      method: 'GET'
    })
  } catch (err) {
    return {valid: false}
  }
  isTokenValid = await isTokenValid.json()
  if (isTokenValid.success === true) {
    return {valid: true, token}
  }
  return {valid: false}
}

/**
 * Updates user with new password
 */
async function changePassword(ev, state, methods) {
  ev.preventDefault()
  const {password, token} = state
  const {setMessage, goToHomepage, changeAlertClass} = methods
  setMessage('')
  changeAlertClass('')
  let passwordSuccess
  try {
    passwordSuccess = await fetch(`/api/account/forgot-password-reset`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      referrer: 'no-referrer',
      body: JSON.stringify({password, token})
    })
  } catch (err) {
    setMessage('Error saving password, please try again.')
    return
  }
  passwordSuccess = await passwordSuccess.json()

  if (passwordSuccess.success) {
    changeAlertClass('alert-success')
    setMessage('Successfully updated password.')
    setTimeout(() => {
      goToHomepage()
    }, 3000)
  } else {
    changeAlertClass('alert-danger')
    setMessage('Error saving password, please try again.')
  }
}

export default class ChangePassword extends Component {
  state = {
    password: '',
    isTokenValid: true,
    token: '',
    message: '',
    alertClass: ''
  }
  async componentDidMount() {
    let isTokenValid = await checkIfValidToken()
    if (!isTokenValid.valid) {
      this.setState({isTokenValid: false})
    } else {
      this.setState({token: isTokenValid.token})
    }
  }

  changePassword = ev => this.setState({password: ev.target.value})
  changeAlertClass = alertClass => this.setState({alertClass})
  setMessage = message => this.setState({message})

  goToHomepage = () => this.props.history.push('/')

  render() {
    const state = {
      password: this.state.password,
      message: this.state.message,
      token: this.state.token,
      alertClass: this.state.alertClass
    }
    const methods = {
      goToHomepage: this.goToHomepage,
      setMessage: this.setMessage,
      changeAlertClass: this.changeAlertClass
    }
    return (
      <React.Fragment>
        <Header />
        <div className="change-password">
          <div className="card">
            <div className="card-body">
              {this.state.isTokenValid ? (
                <form onSubmit={ev => changePassword(ev, state, methods)}>
                  <div className="form-group">
                    <label htmlFor="password">New Password</label>
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      aria-describedby="passwordHelp"
                      placeholder="Enter password"
                      value={this.state.password}
                      onChange={this.changePassword}
                    />
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                  {this.state.message && (
                    <div className={`alert${` ${state.alertClass}`}`}>
                      {this.state.message}
                    </div>
                  )}
                </form>
              ) : (
                <div className="expired-token">
                  <div className="alert alert-info">
                    Your password token has expired, click the link below to get
                    a new token.
                  </div>
                  <Link to="/signin">
                    <button className="btn btn-primary" type="button">
                      Sign In
                    </button>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
