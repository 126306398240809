import React from 'react'
import {Image, Button, Row, Col} from 'react-bootstrap'
import './styles/SectionFive.css'

const SectionFive = () => {
  return (
    <div id="section-five-background">
      <div id="section-five" className="section">
        <Row> 
          <Col md={5} className="image-section">
            <Image src="/assets/icons/buyers-doc.svg" />
            <a>
              <Button
                className="btn-default"
                onClick={() =>
                  window.open(
                    `https://s3.us-east-2.amazonaws.com/claims-put-market-assets/vendorsAgreement.pdf`,
                    '_blank'
                  )
                }
              >
                VIEW VENDOR'S AGREEMENT
              </Button>
            </a>
          </Col>
          <Col md={7} className="text-section">
            <div>
              <h1>
                Vendor’s
                <br />
                Agreement
              </h1>
              <p>
                Vendors sign a Vendor’s Agreement to market their put options 
                exclusively through Claims Put Market for a period of time. 
                Vendors also agree to keep any Investor financial information 
                confidential. Vendors send us an executed copy of the SCP, 
                together with a redline copy of any requested changes to the SCP.
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default SectionFive
