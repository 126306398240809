import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Route, Switch } from 'react-router-dom';
import {
  AdminPanel,
  Footer,
  Landing,
  SignIn,
  SignUp,
  Profile
} from './components';
import { fetchAllClaims, fetchUser } from './store';
import SignAgreement from './components/SignAgreement';
import CreateNewClaim from './components/CreateNewClaim';
import ChangePassword from './components/ChangePassword/ChangePassword';
import FAQ from './components/FAQ';
import CDS from './components/CDS';
import Transactions from './components/Transactions/Transactions';

class App extends Component {
  componentDidMount() {
    this.props.initClaims();
    this.props.initAccount();
  }

  render() {
    return (
      <div>
        {!this.props.account ||
          (!this.props.account.email && (
            <Switch>
              <Route exact path="/" component={Landing} />
              <Route exact path="/faq" component={FAQ} />
              <Route exact path="/putquote" component={CDS} />
              <Route path="/signin" render={() => <SignIn {...this.props} />} />
              <Route path="/signup" component={SignUp} />
              <Route path="/agreement" component={SignAgreement} />
              <Route path="/change-password" component={ChangePassword} />
              <Route
                exact
                path="/transactions"
                render={() => <Transactions {...this.props} />}
              />
              {this.props.account.isAdmin && (
                <Route path="/admin" component={AdminPanel} />
              )}
              <Route component={Landing} />
            </Switch>
          ))}
        {/* Routes placed here are only available after logging in */}
        {this.props.account && this.props.account.email && (
          <Switch>
            <Route exact path="/" component={Landing} />
            <Route exact path="/faq" component={FAQ} />
            <Route exact path="/putquote" component={CDS} />
            <Route path="/profile" component={Profile} />
            <Route
              path="/createclaim"
              render={props => (
                <CreateNewClaim {...props} account={this.props.account} />
              )}
            />
            <Route path="/agreement" component={SignAgreement} />
            <Route path="/change-password" component={ChangePassword} />
            <Route
              exact
              path="/transactions"
              render={() => <Transactions {...this.props} />}
            />
            {this.props.account.isAdmin && (
              <Route path="/admin" component={AdminPanel} />
            )}
            <Route component={Landing} />
          </Switch>
        )}

        {/* Displays our Login component as a fallback */}
        <Footer {...this.props} />
      </div>
    );
  }
}

const mapState = state => {
  return {
    account: state.account,
    claims: state.claims,
    isLoggedIn: !!state.account.id,
    isAdmin: !!state.account.isAdmin
  };
};

const mapDispatch = dispatch => {
  return {
    initClaims() {
      dispatch(fetchAllClaims());
    },
    initAccount() {
      dispatch(fetchUser());
    }
  };
};

export default withRouter(connect(mapState, mapDispatch)(App));
