import React from 'react'
import {Button, Row, Col} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import './styles/CDS.css'

const Broker = () => {
  return (
    <div id="broker-background">
      <div id="broker" className="section">
        <Row className="text-section">
          <Row>
            <Col md={12}>
              <h1>Brokers</h1>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <p>
                We know that credit insurance brokers are trusted advisors to many companies. 
                Our goal is to provide resources to enable you to better serve your Vendor clients. 
                With that in mind, we have developed straightforward agreements, pricing data and 
                access to large, credit-worthy Investors that sell put options to Vendors. Many Investors 
                active in put options prefer to transact with Vendors through our marketplace since 
                they have pre-approved the Simple Claims Put. Feel free to contact us at {' '}
                <a href="mailto:all@cherokeeacq.com">all@cherokeeacq.com</a>{' '}
                to find out how we can partner with you.
              </p>
            </Col>
          </Row>
        </Row>
      </div>
    </div>
  )
}

export default Broker
