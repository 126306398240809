import React from 'react'
import {Image, Button, Row, Col} from 'react-bootstrap'

import './styles/SectionThree.css'

const SectionThree = () => {
  return (
    <div id="section-three-background">
      <div id="section-three" className="section">
        <Row>
          <Col md={7} className="text-section">
            <div className="title-section">
              <h1>Simple Claims Put</h1>
              <p>
                We have developed a Simple Claims Put (“SCP”) agreement that 
                balances the interests of both Vendors and Investors. The SCP 
                provides Vendors with clear definitions of Customer, 
                Expiration Date, and Trigger Event they require to understand 
                how to exercise the SCP in case of a bankruptcy. If a Vendor 
                submits an Exercise Notice pursuant to a Trigger Event, the 
                SCP references a Simple Assignment of Claim (“SAC”) agreement, 
                that is used to assign a valid bankruptcy claim to the Investor. 
              </p>
            </div>
            <Row>
              <Col md={6}>
                <h5>BENEFITS OF THE SCP</h5>
                <p>
                  The SCP gives Vendors the ability to protect valid receivables 
                  by hedging against the risk of a Customer's bankruptcy.
                </p>
              </Col>
              <Col md={6}>
                <h5>EFFICIENT CLOSING</h5>
                <p>
                  Put options requested on our site using the SCP will be signed
                  in advance of the listing by each Vendor, providing both parties 
                  with certainty on terms. If a Vendor and Investor both agree to use 
                  the SCP, transaction costs and delays are minimized.
                </p>
              </Col>
            </Row>
          </Col>
          <Col md={5} className="image-section">
            <Image src="/assets/icons/sac-doc.svg" />
            <a>
              <Button
                className="btn-default"
                onClick={() =>
                  window.open(
                    `https://s3.us-east-2.amazonaws.com/claims-put-market-assets/scpAgreement.pdf`,
                    '_blank'
                  )
                }
              >
                VIEW SCP
              </Button>
            </a>
            <a>
              <Button
                className="btn btn-blue"
                onClick={() =>
                  window.open(
                    `https://www.youtube.com/watch?v=nau8sdKPNcQ`,
                    '_blank'
                  )
                }
              >
                Explainer Video
              </Button>
            </a>
            <a>
              <Button
                className="btn-default"
                onClick={() =>
                  window.open(
                    `https://s3.us-east-2.amazonaws.com/claims-put-market-assets/sac.pdf`,
                    '_blank'
                  )
                }
              >
                VIEW SAC
              </Button>
            </a>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default SectionThree
