import React from 'react';
import { connect } from 'react-redux';
import { Image, Button } from 'react-bootstrap';

import './AdminClaimItem.css';

class AdminClaimItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        ...this.props.info
      },
      deleteOccurring: false,
      deleteMessage: '',
      updateOccurring: false,
      updateMessage: '',
      emailsOccurring: false,
      soldMessage: ''
    };
    this.onInputChange = this.onInputChange.bind(this);
  }

  onInputChange(event) {
    const key = event.target.name;
    const value = event.target.value;
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [key]: value
      }
    }));
  }

  onCheckValueChange = () => {
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        scp: !prevState.form.scp
      }
    }));
  };

  handleClaimDelete = () => {
    const confirm = window.confirm(
      'Are you sure that you would like to delete this claim?'
    );
    if (!confirm) {
      return;
    }
    this.delete();
  };

  delete = async () => {
    const claim = this.props.info;
    this.setState({ deleteOccurring: true });
    let result;
    try {
      result = await fetch('/api/claim/delete', {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrer: 'no-referrer',
        body: JSON.stringify(claim)
      });
      result = await result.json();
    } catch (err) {
      result = { success: false };
    }
    if (result.success) {
      this.setState({ deleteOccurring: false });
      this.props.deleteClaim(claim);
      return { deleted: true };
    } else {
      this.setState({
        deleteOccurring: false,
        deleteMessage:
          'Something went wrong. Please try again in a few minutes.'
      });
      setTimeout(() => {
        this.setState({ deleteMessage: '' });
      }, 4000);
      this.props.deleteClaim(claim);
      return { deleted: false };
    }
  };

  update = async () => {
    const claim = this.state.form;
    this.setState({ updateOccurring: true });
    let result;
    try {
      result = await fetch(`/api/claim/${claim._id}`, {
        method: 'PUT',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrer: 'no-referrer',
        body: JSON.stringify({ claim })
      });
      result = await result.json();
    } catch (err) {
      result = { success: false };
    }
    if (result.success) {
      this.setState({ updateOccurring: false });
      return { updated: true };
    } else {
      this.setState({
        updateOccurring: false,
        updateMessage:
          'Something went wrong. Please try again in a few minutes.'
      });
      setTimeout(() => {
        this.setState({ updateMessage: '' });
      }, 4000);
      return { updated: false };
    }
  };

  handleSold = async () => {
    const confirm = window.confirm(
      'Are you sure that you would like to notify all users that this is sold and delete the claim?'
    );
    if (!confirm) {
      return;
    }
    this.setState({ emailsOccurring: true });
    this.delete('sold');

    const account = this.props.account;
    const claim = this.props.info;
    let result;
    try {
      result = await fetch('/api/claim/sold', {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrer: 'no-referrer',
        body: JSON.stringify({ account, claim })
      });
      result = await result.json();
    } catch (err) {
      result = { success: false };
    }
    if (result.success) {
      this.setState({
        soldMessage: 'Claim was deleted and all emails were sent.',
        emailsOccurring: false
      });
    } else {
      this.setState({
        soldMessage: 'Something went wrong. Please try again in a few minutes.',
        emailsOccurring: false
      });
      setTimeout(() => {
        this.setState({ soldMessage: '' });
      }, 4000);
    }
  };

  render() {
    const labelMap = {
      maxClaimAmount: 'Max Claim Amount',
      term: 'Term',
      purchaseRate: 'Purchase Rate',
      feeRate: 'Fee Rate',
      debtor: 'Customer',
      buyer: 'Vendor',
      expirationDate: 'Expiration Date',
      effectiveDate: 'Effective Date',
      adminFee: 'Admin. Fee',
      scp: 'SCP',
      listingExpiration: 'Listing Exp Date'
    };

    return (
      <div id="admin-claim-item">
        <div className="subjectContainer">
          <div className="creditor-name">{this.props.info._id}</div>
          <div className="tabs">
            <button
              type="button"
              className="btn btn-danger"
              disabled={this.state.deleteOccurring}
              onClick={this.handleClaimDelete}
            >
              Delete
            </button>
            <button
              type="button"
              className="btn btn-warning"
              disabled={this.state.emailsOccurring}
              onClick={this.handleSold}
            >
              Sold
            </button>
            {this.state.deleteMessage && (
              <div className="alert alert-danger" role="alert">
                {this.state.deleteMessage}
              </div>
            )}
            {this.props.tabOpen === this.state.form._id ? (
              <Image
                src="/assets/icons/minus.svg"
                onClick={() => this.props.handleTabChange('')}
              />
            ) : (
              <Image
                src="/assets/icons/plus.svg"
                onClick={() => this.props.handleTabChange(this.state.form._id)}
              />
            )}
          </div>
        </div>
        {this.props.tabOpen === this.state.form._id && (
          <div className="editFormContainer">
            {Object.keys(this.props.info).map(item => {
              let jsx;
              if (item === 'scp' || item === 'creditor') {
                jsx = null;
              } else if (item === 'expirationDate' || item === 'effectiveDate' || item === 'listingExpiration') {
                jsx = (
                  <div className="input-container">
                    <label className="option">{labelMap[item]}</label>
                    <input
                      type="date"
                      name={item}
                      value={this.state.form[item]}
                      onChange={this.onInputChange}
                    />
                  </div>
                );
              } else if (item !== '__v' && item !== '_id') {
                jsx = (
                  <div className="input-container">
                    <label className="option">{labelMap[item]}</label>
                    <input
                      type="text"
                      name={item}
                      value={this.state.form[item]}
                      onChange={this.onInputChange}
                    />
                  </div>
                );
              }
              return <React.Fragment key={item}>{jsx}</React.Fragment>;
            })}
            <div className="btn-container">
              <Button
                className="btn-default"
                disabled={this.state.updateOccurring}
                onClick={this.update}
              >
                SAVE
              </Button>
              {this.state.updateMessage && (
                <div className="alert alert-danger" role="alert">
                  {this.state.updateMessage}
                </div>
              )}
              {this.state.soldMessage && (
                <div className="alert alert-danger" role="alert">
                  {this.state.soldMessage}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapState = state => {
  return {
    account: state.account
  };
};

export default connect(mapState)(AdminClaimItem);
