import React from 'react';
import moment from 'moment';
import Header from '../Header';
import BootstrapTable from 'react-bootstrap-table-next';
import {
  Row, Col

} from 'react-bootstrap';

import './Transactions.css';

export default class Transactions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      transactions: []
    };

    this.columns = this.columns.bind(this);
    this.sortDate = this.sortDate.bind(this);
    this.sortMoney = this.sortMoney.bind(this);

    this.calculateTotalAmount = 
      this.calculateTotalAmount.bind(this);

    this.paddingBasedOnScreenWidth = 
      this.paddingBasedOnScreenWidth.bind(this);
  }

  columns() { 
    return [
      {
        dataField: 'transactionDate',
        text: 'Purchased',
        sort: true,
        sortFunc: (a,b,order) => {
          if (order === 'asc') {
            const aDate = moment(a);
            const bDate = moment(b);
            
            if (aDate < bDate) return -1;
            if (aDate > bDate) return 1;
            return 0;
          }
      
          const aDate = moment(a);
          const bDate = moment(b);
            
          if (aDate > bDate) return -1;
          if (aDate < bDate) return 1;
          return 0;
        }, // Leave this here!
        onSort: (field, order) => 
          this.sortDate(field, order)
      }, 
      {
        dataField: 'listDate',
        text: 'Listed',
        sort: true,
        sortFunc: () => {}, // Leave this here!
        onSort: (field, order) => 
          this.sortDate(field, order)
      }, 
      {
        dataField: 'customer',
        text: 'Customer',
        sort: true,
        sortFunc: () => {}, // Leave this here!
        onSort: (field, order) => 
          this.sortDebtor(field, order)
      },  
      {
        dataField: 'maxAmount',
        text: 'Maximum Claim Amount',
        sort: true,
        sortFunc: () => {}, // Leave this here!
        onSort: (_, order) => this.sortMoney(order)
      }
    ];
  }

  sortDebtor(field, order) {
    const transactions = this.state.transactions;

    if (order === 'asc') {
      const sorted = transactions.sort((a, b) => {
        if (a[field] > b[field]) return 1;
        if (a[field] < b[field]) return -1;
        return 0;
      });

      this.setState({ transactions: sorted });

      return;
    }

    const sorted = transactions.sort((a, b) => {
      if (a[field] > b[field]) return -1;
      if (a[field] < b[field]) return 1;
      return 0;
    });

    this.setState({ transactions: sorted });
  }

  sortDate(field, order) {
    const transactions = this.state.transactions;

    if (order === 'asc') {
      const sorted = transactions.sort((a, b) => {
        const aDate = moment(a[field]);
        const bDate = moment(b[field]);
        
        if (aDate < bDate) return -1;
        if (aDate > bDate) return 1;
        return 0;
      });

      this.setState({ transactions: sorted });

      return;
    }

    const sorted = transactions.sort((a, b) => {
      const aDate = moment(a[field]);
      const bDate = moment(b[field]);
        
      if (aDate > bDate) return -1;
      if (aDate < bDate) return 1;
      return 0;
    });

    this.setState({ transactions: sorted });
  }

  sortMoney(order) {
    const transactions = this.state.transactions;

    if (order === 'asc') {
      const sorted = transactions.sort((a, b) => {
        const aVal = parseFloat(
          a.maxAmount.substring(1).replaceAll(',', ''));

        const bVal = parseFloat(
          b.maxAmount.substring(1).replaceAll(',', ''));

        if (aVal > bVal) return -1;
        if (aVal < bVal) return 1;
        return 0;
      });

      this.setState({ transactions: sorted });

      return;
    }

    const sorted = transactions.sort((a, b) => {
      const aVal = parseFloat(
        a.maxAmount.substring(1).replaceAll(',', ''));

      const bVal = parseFloat(
        b.maxAmount.substring(1).replaceAll(',', ''));

      if (aVal < bVal) return -1;
      if (aVal > bVal) return 1;
      return 0;
    });

    this.setState({ transactions: sorted });
  }

  async componentDidMount() {
    console.log('Fetching Transactions');
    fetch('/api/transaction')
      .then((response) => response.json())
      .then((transactions) => {
        for (const i in transactions) {
          let transaction = transactions[i];
          console.log(transaction);
    
          const listDate = new Date(transaction.listDate);
          const transactionDate = new Date(transaction.transactionDate);
    
          transaction.maxAmount = 
            `$${Number(transaction.maxAmount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
    
          transaction.listDate = listDate.toLocaleDateString(undefined, { dateStyle: 'medium' });
          transaction.transactionDate = transactionDate.toLocaleDateString(undefined, {
            dateStyle: 'medium'
          });
          console.log('updated');
          console.log(transaction);
        }
    
        this.setState({ transactions: transactions });
      })
      .catch((err) => { /* Todo: Add Error Handling */ });
  }

  calculateTotalAmount(transactions) {
    const value = transactions.reduce((amount, transaction) => 
      amount += parseFloat(transaction.maxAmount.replaceAll(',', '').replaceAll('$', '')), 0);
    
    return Number(value)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,');

  }
  
  paddingBasedOnScreenWidth() {
    const width = window.innerWidth;
  
    if (width < 768) {
      return {
        paddingTop: 'calc(120px + 8%)'
      };
    }
  
    return {
      paddingTop: 'calc(50px + 8%)'
    };
  }

  render() {
    return (
      <React.Fragment>
        <Header {...this.props} />
        <div className="transactions transactions-bg">
          <div
            className="transactions-section section"
            style={this.paddingBasedOnScreenWidth()}>

            <Row>
              <Col md={1} />

              <Col md={10}>
                {(this.state.transactions.length > 0) ? (
                  <div>
                    <h1 className="display-4">Transaction History</h1>
                    <p className="lead">
                     Vendors have purchased{' '}
                      <span className="text-success">
                        <span className="dollar">$</span>
                        <span className="underline">
                          {this.calculateTotalAmount(this.state.transactions)}
                        </span>
                      </span>{' '}
                      of put options in {this.state.transactions.length} transactions.
                    </p>
                  </div>
                ) : null }

                <div className="table-responsive">
                  <BootstrapTable
                    keyField='_id' 
                    columns={this.columns()}
                    classes='table table-dark'
                    headerWrapperClasses='thead-light'
                    data={this.state.transactions}
                    defaultSorted={[{
                      order: 'desc',
                      dataField: 'transactionDate' 
                    }]} />

                </div>
              </Col>

              <Col md={1} />
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
