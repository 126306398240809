import React from 'react'
import {Image, Button, Row, Col, Container} from 'react-bootstrap'
import './styles/SectionSix.css'

const SectionSix = () => {
  return (
    <div id="section-six-background">
      <div id="section-six" className="section">
        <Row>
          <Col md={12} className="title-container">
            <h1>Legal</h1>
            <Image src="/assets/icons/legal.svg" />
          </Col>
        </Row>
        <Row>
          <Col md={12} className="description-container">
            <p>
              Claims Put Market is a platform administered by Cherokee Hybrid Markets, Inc.  
              Investors and Vendors acknowledge that we have entered into an agreement with each 
              Vendor whose requested put option is listed on our website granting 
              us the exclusive right to find a put option Investor for a period of 
              time. All parties agree not to circumvent or interfere with our rights 
              under the agreements we have with other parties. Our affiliates may 
              offer to sell put options (after five business days of a Vendor’s listing) 
              or request to purchase put options on the site.
            </p>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default SectionSix
