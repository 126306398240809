import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Image } from 'react-bootstrap';
import history from '../../history';
import { connect } from 'react-redux';
import * as Scroll from 'react-scroll';
import './footer.css';

const animateScroll = Scroll.animateScroll;

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.checkLocation = this.checkLocation.bind(this);
  }

  checkLocation(position) {
    var oldIsHome =
      history.location.pathname === '/' ||
      history.location.pathname === '/home';

    if (oldIsHome === false) {
      history.push('/');
    }

    this.forceUpdate();

    setTimeout(function() {
      var destination = document.querySelector('#' + position);
      const elementPosition = destination.offsetTop - 30;
      animateScroll.scrollTo(elementPosition, {
        smooth: true,
        duration: 500
      });
    }, 200);
  }

  render() {
    return (
      <div id="footer" className="section">
        <footer className="page-footer">
          <div className="container-fluid text-md-left">
            <div className="row">
              <img
                src="/assets/images/logo-one-white.png"
                className="img-fluid"
                alt="claims put market logo"
              />
            </div>
            <div className="row">
              <div className="col-md-6 mt-md-0 mt-3 cm-description">
                <p>
                  Claims Put Market is a new platform dedicated to simplifying
                  the market for put options. Our goal is to bring together
                  Vendors and Investors in put options through a transparent
                  marketplace that reduces transaction costs.
                </p>
                <div className="icon-container">
                  <a
                    href="https://www.linkedin.com/company/cherokee-acquisition/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image src="/assets/icons/linkedin.svg" className="icon" />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCbHYM790EvlRgMKTQTo7RDQ"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image
                      src="/assets/icons/youtube.png"
                      className="icon icon-right"
                    />
                  </a>
                </div>
              </div>
              <hr className="clearfix w-100 d-md-none pb-3" />
              <div className="col-md-3 mb-md-0 mb-3">
                <ul className="list-unstyled">
                  <li>
                    <a
                      className="badge"
                      onClick={() => this.checkLocation('section-two')}
                    >
                      How it Works
                    </a>
                  </li>
                  <li>
                    <a
                      className="badge"
                      onClick={() =>
                        this.checkLocation('section-three-background')
                      }
                    >
                      SCP (Simple Claims Put)
                    </a>
                  </li>
                  <li>
                    <a
                      className="badge"
                      onClick={() =>
                        this.checkLocation('section-five-background')
                      }
                    >
                      Vendor's Agreement
                    </a>
                  </li>
                  <li>
                    <a
                      className="badge"
                      onClick={() =>
                        this.checkLocation('section-four-background')
                      }
                    >
                      Investor's Agreement
                    </a>
                  </li>
                  <li>
                    <a
                      className="badge"
                      onClick={() => this.props.history.push('/transactions')}
                    >
                      Transactions
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-3 mb-md-0 mb-3">
                <ul className="list-unstyled">
                  <li>
                    <a
                      className="badge"
                      onClick={() =>
                        this.checkLocation('section-one-background')
                      }
                    >
                      Put Options Requested
                    </a>
                  </li>
                  <li>
                    <Link to="/faq" className="badge">
                      FAQ
                    </Link>
                  </li>
                  <li>
                    <a
                      className="badge"
                      onClick={() => this.checkLocation('section-six')}
                    >
                      Legal
                    </a>
                  </li>
                  <li>
                    <a
                      className="badge"
                      onClick={() =>
                        this.checkLocation('section-seven-background')
                      }
                    >
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="contact-container">
            {!this.props.account.name && (
              <div className="onboarding-btn-container">
                <Button className="btn-default" href="/signup">
                  Sign Up
                </Button>
                <Button className="no-background" href="/signin">
                  Log In
                </Button>
              </div>
            )}
          </div>
          <div className="footer-copyright text-center py-3">
            <a href="http://cherokeeacq.com/" target="_blank">
              © 2023 Cherokee Hybrid Markets, Inc.
            </a>
          </div>
          <div className="footer-meeplabs text-center">
            <a href="http://ravennainteractive.com/" target="_blank">
              Site Developed by Ravenna
            </a>
          </div>
        </footer>
      </div>
    );
  }
}

const mapState = state => {
  return {
    account: state.account
  };
};

export default connect(mapState)(Footer);
